import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.job;

export const getJobsList = createSelector(selectState, (jobs) => ({
	jobsList: get(jobs, "jobList", []),
	totalCount: get(jobs, "totalCount", 0),
}));

export const getjobData = createSelector(selectState, (job) =>
	get(job, "jobData", [])
);
