export function parseGoogleAddressComponents(components, place) {
	let address1 = place.name + " ";
	let city = "";
	let state = "";
	let zip = "";
	let country = "";

	components.forEach((component) => {
		if (component.types.includes("premise")) {
			address1 += component.long_name + " ";
		}
		if (component.types.includes("sublocality_level_2")) {
			address1 += component.long_name + " ";
		}
		if (component.types.includes("sublocality_level_1")) {
			address1 += component.long_name + " ";
		}
		if (component.types.includes("locality")) {
			address1 += component.long_name + " ";
		}
		if (component.types.includes("administrative_area_level_3")) {
			address1 += component.long_name + " ";
		}
		if (component.types.includes("administrative_area_level_2")) {
			city = component.long_name + " ";
		}
		if (component.types.includes("administrative_area_level_1")) {
			state += component.long_name + " ";
		}
		if (component.types.includes("country")) {
			country = component.long_name + " ";
		}
		if (component.types.includes("postal_code")) {
			zip = component.long_name + " ";
		}
	});

	return {
		address1,
		city,
		state,
		zip,
		country,
	};
}
