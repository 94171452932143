import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.user;

export const getUsersList = createSelector(selectState, (user) =>
	get(user, "usersList", [])
);

export const getUsersLoading = createSelector(selectState, (user) =>
	get(user, "usersLoading", false)
);

export const getTimesheetData = createSelector(selectState, (user) => ({
	timesheetData: get(user, "timesheetData", []),
	totalCount: get(user, "totalTimesheetEntries", 0),
}));
