import React from "react";
import "./timesheet.scss";
import WeekTimeSheet from "./WeekTimeSheet";
import TimesheetEntries from "./TimesheetEntries";

const Timesheet = () => {
	return (
		<div className="timesheet-container">
			<div className="mt-5">
				<WeekTimeSheet />
			</div>
			<div className="mt-5">
				<TimesheetEntries />
			</div>
		</div>
	);
};

export default Timesheet;
