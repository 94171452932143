import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimesheetData } from "selectors/userSelector";
import { fetchTimesheetData } from "actions/userActions";
import DataGridTable from "components/Table/DataGrid";

export const TimesheetEntries = () => {
	const [pageModel, setPageModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const dispatch = useDispatch();
	const { timesheetData, totalCount } = useSelector(getTimesheetData);

	useEffect(() => {
		dispatch(fetchTimesheetData(pageModel));
	}, [dispatch, pageModel]);

	const columns = [
		{
			field: "projectName",
			headerName: "Project",
			width: 250,
		},
		{
			field: "jobName",
			headerName: "Project",
			width: 250,
		},
		{
			field: "hours",
			headerName: "Hours",
			type: "number",
			width: 150,
			align: "center",
			sortable: false,
			headerAlign: "center",
		},
		{
			field: "startTime",
			headerName: "Start Date",
			width: 250,
		},
		{
			field: "endTime",
			headerName: "End Date",
			width: 250,
		},
	];

	return (
		<>
			{totalCount > 0 ? (
				<DataGridTable
					showToolbar={false}
					columns={columns}
					data={timesheetData}
					checkboxSelection={false}
					defineRowId={(row) => {
						return row.timeLogId;
					}}
					pagination={totalCount > 10}
					page
					totalCount={totalCount}
					paginationModel={pageModel}
					onPaginationChange={(props) => {
						setPageModel(props);
					}}
				/>
			) : (
				<>No Data Available</>
			)}
		</>
	);
};
export default TimesheetEntries;
