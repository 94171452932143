import { ActionConstants } from "constants";

const initialState = {
	companiesList: [],
	loading: false,
	status: "success",
	text: "",
	showSnackbar: false,
};

const companyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.GET_COMPANIES_SUCCESS:
			return {
				...state,
				companiesList: action.payload.companiesList,
				totalCount: action.payload.totalCount,
			};
		default:
			return state || {};
	}
};

export default companyReducer;
