import RenderTabs from "components/Tabs/Tabs";
import React, { useEffect } from "react";
import projectCover from "assets/app/project_cover.jpg";
import {
	Overview,
	TaskManager,
	UserManagement,
	ProjectTimelines,
	Analysis,
} from "./tabComponents";
import { jobGetAll } from "actions/jobActions";
import { fetchProjectById } from "actions/projectActions";
import { taskGetAll } from "actions/taskActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectData } from "selectors/projectSelector";
import { Typography } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";

const tabs = [
	{
		key: "overview",
		label: "Overview",
		TabComponent: Overview,
	},
	{
		key: "taskManager",
		label: "Task Manager",
		TabComponent: TaskManager,
	},
	{
		key: "userManagement",
		label: "User Management",
		TabComponent: UserManagement,
	},
	{
		key: "timelineView",
		label: "Timeline View",
		TabComponent: ProjectTimelines,
	},
	{
		key: "analysis",
		label: "Cost Analysis",
		TabComponent: Analysis,
	},
];

export const ViewProject1 = () => {
	const [selectedTab, updateTab] = React.useState("overview");
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const projectData = useSelector(getProjectData);

	useEffect(() => {
		dispatch(fetchProjectById(params.projectId));
		dispatch(taskGetAll({ projectId: params.projectId }));
		dispatch(jobGetAll({ projectId: params.projectId }));
	}, [dispatch, params.projectId]);

	return (
		<>
			<div>
				<button
					style={{ width: "6rem", height: "2rem" }}
					className="secondaryButton"
					onClick={() => navigate("/projects")}
				>
					<ArrowBackOutlined style={{ fontSize: "1rem" }} /> {"Back"}
				</button>
			</div>
			<div>
				<Typography className="h1 mt-3">{projectData.projectName}</Typography>
			</div>
			<div className="project-header">
				<img
					src={projectCover}
					alt="Project Cover"
					className="full-width mt-3 mb-2"
					style={{ height: "15rem" }}
				/>
			</div>
			<div className="project-tabs tabs">
				<RenderTabs
					tabs={tabs}
					selectedTab={selectedTab}
					onTabClick={(newTab) => {
						updateTab(newTab);
					}}
					tabParams={{ projectId: params.projectId }}
				/>
			</div>
			<div className="project-tab-content"></div>
		</>
	);
};

export default ViewProject1;
