// https://corewebapi20240513161440.azurewebsites.net/

import axios from "axios";

const request = async (request) => {
	const token = localStorage.getItem("token") || "";
	return await axios({
		method: request.method,
		url: request.url,
		data: request.body || {},
		headers: {
			...(request.headers || { "Content-Type": "application/json" }),
			Authorization: `Bearer ${token}`,
		},
	});
};

export default request;
