import React from "react";

// pages
import Dashboard from "pages/dashboard";

import ContractorBusinessProfile from "pages/profile/ContractorProfile";
// import { loadUserDetails } from "actions/login_registrationActions";
import CompaniesList from "pages/companies/CompaniesList";
import Projects from "pages/projects/Projects";
import AddNewProperty from "pages/property/AddNewProperty";
import Property from "pages/property/Properties";
import Jobs from "pages/job/Jobs";
import PurchaseOrders from "pages/orders/PurchaseOrders";
import Invoice from "pages/invoice/Invoice";
import Payments from "pages/orders/Payments";
import Tasks from "pages/task/Tasks";
import GanttChart from "pages/GanttChart/GanttChart";
import {
	ViewJob,
	ViewProject,
	ViewTask,
	Timesheet,
	UsersList,
	UnderDevelopment,
} from "./loadables";
import { Navigate } from "react-router-dom";
import BudgetEstimationTemplate from "pages/estimations/Estimations";

export const externalRoutes = [
	{
		path: "/profile/contractor/businessProfile",
		component: <ContractorBusinessProfile />,
	},
];

const builderRoutes = [
	{
		path: "/builder/purchaseOrders",
		component: <PurchaseOrders />,
		roles: ["BuilderAdmin", "BuilderGeneralManager", "BuilderAccountant"],
	},
	{
		path: "/builder/invoiceOrders",
		component: <Invoice />,
		roles: ["BuilderAdmin", "BuilderGeneralManager", "BuilderAccountant"],
	},
	{
		path: "/builder/payments",
		component: <Payments />,
		roles: ["BuilderAdmin", "BuilderGeneralManager", "BuilderAccountant"],
	},

	{
		path: "/builder/estimations",
		component: <BudgetEstimationTemplate />,
		roles: ["BuilderAdmin", "BuilderGeneralManager", "BuilderAccountant"],
	},
];

export const routePaths = [
	{ path: "/", exact: true, component: <Navigate to="/dashboard" /> },
	{ path: "/", component: <Navigate to="/dashboard" /> },
	{ path: "/dashboard", component: <Dashboard /> },
	{
		path: "/profile/homeOwner/addProperty",
		component: <AddNewProperty />,
	},
	{
		path: "/companies",
		component: <CompaniesList />,
	},
	{
		path: "/projects",
		component: <Projects />,
		roles: [
			"BuilderAdmin",
			"BuilderGeneralManager",
			"BuilderTeamMember",
			"BuilderSaleExecutive",
			"SupplierAdmin",
		],
	},
	{
		path: "/projects/view/:projectId",
		component: <ViewProject />,
		roles: [
			"BuilderAdmin",
			"BuilderGeneralManager",
			"BuilderTeamMember",
			"BuilderSaleExecutive",
			"SupplierAdmin",
		],
	},
	{
		path: "/properties",
		component: <Property />,
		roles: [
			"BuilderAdmin",
			"BuilderGeneralManager",
			"BuilderTeamMember",
			"SupplierAdmin",
		],
	},
	{
		path: "/properties/view/:propertyId",
		component: <Property />,
		roles: [
			"BuilderAdmin",
			"BuilderGeneralManager",
			"BuilderTeamMember",
			"SupplierAdmin",
		],
	},
	{
		path: "/jobs",
		component: <Jobs />,
	},
	{
		path: "/jobs/view/:jobId",
		component: <ViewJob />,
	},
	{
		path: "/tasks",
		component: <Tasks />,
	},
	{
		path: "/tasks/view/:taskId",
		component: <ViewTask />,
	},
	{
		path: "/purchaseOrders",
		component: <PurchaseOrders />,
		roles: ["BuilderAdmin", "BuilderGeneralManager", "SupplierAdmin"],
	},
	{
		path: "/invoiceOrders",
		component: <Invoice />,
		roles: ["BuilderAdmin", "BuilderGeneralManager", "SupplierAdmin"],
	},
	{
		path: "/payments",
		component: <Payments />,
		roles: ["BuilderAdmin", "BuilderGeneralManager", "SupplierAdmin"],
	},
	{ path: "/timelines", component: <GanttChart /> },
	{ path: "/timesheet", component: <Timesheet /> },
	{
		path: "/users",
		component: <UsersList />,
		roles: ["BuilderAdmin", "BuilderGeneralManager"],
	},
	{ path: "/estimations", component: <BudgetEstimationTemplate /> },
	{ path: "/page/underDevelopment", component: <UnderDevelopment /> },
	...builderRoutes,
];

export default routePaths;
