import React, { useState } from "react";

const initialData = [
	{
		task: "Plans & Specifications",
		vendor: "",
		hrs: 0,
		rate: 0,
		units: 0,
		unitCost: 0,
		fixedCost: 0,
		budget: 0,
		actual: 0,
		underOver: 0,
	},
	{
		task: "Plan Review",
		vendor: "",
		hrs: 0,
		rate: 0,
		units: 0,
		unitCost: 0,
		fixedCost: 0,
		budget: 0,
		actual: 0,
		underOver: 0,
	},
	// Add more initial rows as needed
];

const BudgetEstimationTemplate = () => {
	const [rows, setRows] = useState(initialData);
	const [total, setTotal] = useState(0);

	const handleInputChange = (index, field, value) => {
		const newRows = [...rows];
		newRows[index][field] = value;
		setRows(newRows);
	};

	const calculateRow = (row) => {
		const laborCost = row.hrs * row.rate;
		const materialCost = row.units * row.unitCost;
		const totalCost = laborCost + materialCost + row.fixedCost;
		return {
			...row,
			budget: totalCost,
			underOver: row.actual - totalCost,
		};
	};

	const handleCalculate = () => {
		const calculatedRows = rows.map(calculateRow);
		const totalBudget = calculatedRows.reduce(
			(sum, row) => sum + row.budget,
			0
		);
		setRows(calculatedRows);
		setTotal(totalBudget);
	};

	return (
		<div>
			<h2>Construction Budget Template</h2>
			<table>
				<thead>
					<tr>
						<th>Task</th>
						<th style={{ width: "10rem" }}>Vendor/Contractor</th>
						<th style={{ width: "10rem" }}>HRS</th>
						<th style={{ width: "10rem" }}>Rate</th>
						<th style={{ width: "10rem" }}>Units</th>
						<th style={{ width: "10rem" }}> $/Unit</th>
						<th style={{ width: "10rem" }}>Fixed Cost</th>
						<th style={{ width: "10rem" }}>Budget</th>
						<th style={{ width: "10rem" }}>Actual</th>
						<th style={{ width: "10rem" }}>Under/Over</th>
					</tr>
				</thead>
				<tbody>
					{rows.map((row, index) => (
						<tr key={index}>
							<td>{row.task}</td>
							<td style={{ width: "10rem" }}>
								<input
									value={row.vendor}
									onChange={(e) =>
										handleInputChange(index, "vendor", e.target.value)
									}
								/>
							</td>
							<td style={{ width: "10rem" }}>
								<input
									type="number"
									value={row.hrs}
									onChange={(e) =>
										handleInputChange(index, "hrs", parseFloat(e.target.value))
									}
								/>
							</td>
							<td style={{ width: "10rem" }}>
								<input
									type="number"
									value={row.rate}
									onChange={(e) =>
										handleInputChange(index, "rate", parseFloat(e.target.value))
									}
								/>
							</td>
							<td style={{ width: "10rem" }}>
								<input
									type="number"
									value={row.units}
									onChange={(e) =>
										handleInputChange(
											index,
											"units",
											parseFloat(e.target.value)
										)
									}
								/>
							</td>
							<td style={{ width: "10rem" }}>
								<input
									type="number"
									value={row.unitCost}
									onChange={(e) =>
										handleInputChange(
											index,
											"unitCost",
											parseFloat(e.target.value)
										)
									}
								/>
							</td>
							<td style={{ width: "10rem" }}>
								<input
									type="number"
									value={row.fixedCost}
									onChange={(e) =>
										handleInputChange(
											index,
											"fixedCost",
											parseFloat(e.target.value)
										)
									}
								/>
							</td>
							<td style={{ width: "10rem" }}>${row.budget.toFixed(2)}</td>
							<td style={{ width: "10rem" }}>
								<input
									type="number"
									value={row.actual}
									onChange={(e) =>
										handleInputChange(
											index,
											"actual",
											parseFloat(e.target.value)
										)
									}
								/>
							</td>
							<td style={{ width: "10rem" }}>${row.underOver.toFixed(2)}</td>
						</tr>
					))}
				</tbody>
			</table>
			<button onClick={handleCalculate}>Calculate</button>
			<div>Total Estimation: ${total.toFixed(2)}</div>
		</div>
	);
};

export default BudgetEstimationTemplate;
