import React, { useEffect, useRef } from "react";
import {
	Gantt,
	Task,
	EventOption,
	StylingOption,
	ViewMode,
	DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";

import { projectsData, projectData } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "selectors/projectSelector";
import { fetchProjects } from "actions/projectActions";
import { useNavigate } from "react-router-dom";

export const GanttChart = () => {
	const ganttRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { projectsList } = useSelector(getProjects);

	const ganttFormat = (projectsList) => {
		return projectsList.map((project) => ({
			start: new Date(project.startDate),
			end: new Date(project.completionDate),
			name: project.projectName,
			type: "project",
			id: project.projectId,
		}));
	};

	const eventOptions = {
		onSelect: (props) => {
			console.log("CHeck on the onSelect of ROw : ", props);
		},
		onClick: (props) => {
			console.log("CHeck on the onClick of ROw : ", props);
			navigate(`/projects/view/${props.id}`);
		},
		onDoubleClick: (props) => {
			console.log("CHeck on the onDoubleClick of ROw : ", props);
		},
	};

	const formattedProjects = ganttFormat(projectsList);

	useEffect(() => {
		dispatch(fetchProjects());
	}, [dispatch]);

	return (
		<>
			{projectsList.length > 0 ? (
				<div>
					<Gantt
						tasks={ganttFormat(projectsList)}
						viewMode={ViewMode.Month}
						ref={ganttRef}
						{...eventOptions}
					/>
					{/* <GanttChart2 /> */}
				</div>
			) : null}
		</>
	);
};

export const RenderGanttChart = ({ projectsList, ganttRef, viewMode }) => {
	console.log("Rendering Gantt CHart");
	return <Gantt tasks={projectsList} viewMode={viewMode} ref={ganttRef} />;
};
export default GanttChart;
