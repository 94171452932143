import { makeStyles } from "@mui/styles";

const drawerWidth = "17rem";

export default makeStyles((theme) => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: "10rem",
		[theme.breakpoints.down("sm")]: {
			width: drawerWidth,
		},
	},
	toolbar: {
		...theme.mixins.toolbar,
		// [theme.breakpoints.down("sm")]: {
		// 	display: "none",
		// },
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},

	sidebarList: {
		paddingRight: "0.5rem",
		alignSelf: "center",
	},

	mobileBackButton: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(3),
		[theme.breakpoints.only("sm")]: {
			marginTop: theme.spacing(0.625),
		},
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
}));
