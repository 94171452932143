export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_ERROR = "ADD_NEW_USER_ERROR";

export const INVITE_NEW_USER = "INVITE_NEW_USER";
export const INVITE_NEW_USER_SUCCESS = "INVITE_NEW_USER_SUCCESS";
export const INVITE_NEW_USER_ERROR = "INVITE_NEW_USER_ERROR";

export const SAVE_TIME_SHEET = "SAVE_TIME_SHEET";
export const SAVE_TIME_SHEET_SUCCESS = "SAVE_TIME_SHEET_SUCCESS";
export const SAVE_TIME_SHEET_ERROR = "SAVE_TIME_SHEET_ERROR";

export const GET_TIME_SHEET = "GET_TIME_SHEET";
export const GET_TIME_SHEET_SUCCESS = "GET_TIME_SHEET_SUCCESS";
export const GET_TIME_SHEET_ERROR = "GET_TIME_SHEET_ERROR";
