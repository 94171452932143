import React, { useState, useEffect } from "react";
import AddNewProperty from "./AddNewProperty";
import { useDispatch, useSelector } from "react-redux";
import { propertyGetAll } from "actions/propertyActions";
import { getProperties } from "selectors/propertySelectors";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { MenuItem, Typography } from "@mui/material";
import BreadCrumb from "components/BreadCrumb";
import DataGridTable from "components/Table/DataGrid";
import { useNavigate } from "react-router-dom";
import BurgerMenu from "components/Menu/BurgerMenu";
import { Delete, EditOutlined } from "@mui/icons-material";
import { get } from "lodash";

const columns1 = [
	{ id: "propertyId", label: "S.no", minWidth: 50 },
	{ id: "propertyName", label: "Property Name", minWidth: 150 },
	{
		id: "legalDescription",
		label: "Description",
		minWidth: "auto",
	},
	{
		id: "propertyType",
		label: "Type",
		minWidth: "auto",
	},
	{
		id: "architectureStyle",
		label: "Style",
		minWidth: "auto",
	},
	{
		id: "bedroom",
		label: "Bedrooms",
		minWidth: "auto",
	},
	{
		id: "bath",
		label: "Bathrooms",
		minWidth: "auto",
	},
	{
		id: "garages",
		label: "Garages",
		minWidth: "auto",
	},
	{
		id: "stories",
		label: "Stories",
		minWidth: "auto",
	},
	{
		id: "buildingSize",
		label: "Building Size",
		minWidth: "auto",
	},
	{
		id: "lotSize",
		label: "Lot Size",
		minWidth: "auto",
	},
	{
		id: "propertyLocation",
		label: "Location",
		minWidth: "auto",
		format: (value) => (
			<a href={value} target="_blank" rel="noreferrer" className={"link-text"}>
				{"View Location"}{" "}
			</a>
		),
	},
];

const columns = (props) => [
	{
		field: "propertyId",
		headerName: "S.no",
		sortable: false,
		width: 50,
		resizable: false,
		renderCell: (rowData) => {
			return (
				<p
					className=""
					onClick={() =>
						props.navigate(`/properties/view/${rowData.row.propertyId}`)
					}
				>
					{rowData.formattedValue}
				</p>
			);
		},
	},
	{
		field: "propertyName",
		headerName: "Property Name",
		sortable: false,
		width: 200,
		resizable: false,
		renderCell: (rowData) => {
			return (
				<p
					className="link-text"
					onClick={() =>
						props.navigate(`/properties/view/${rowData.row.propertyId}`)
					}
				>
					{rowData.formattedValue}
				</p>
			);
		},
	},
	{
		field: "propertyType",
		headerName: "Type",
		resizable: false,
		width: 200,
	},
	{
		field: "startDate",
		headerName: "Start Date",
		// resizable: false,
		width: 100,
	},
	{
		field: "propertyLocation",
		headerName: "Location",
		width: 100,
		renderCell: (rowData) => {
			return (
				<p className="link-text">
					<a href={rowData.propertyLocation} target="_blank" rel="noreferrer">
						{"View Location"}
					</a>
				</p>
			);
		},
		sortable: false,
	},
	{
		field: "actions",
		type: "actions",
		width: 100,
		align: "left",
		getActions: (params) => [
			<BurgerMenu>
				<MenuItem
					onClick={() => {
						console.log("Check the Params : ", params);
						props.setEditProperty(true);
						props.setAddNewProperty(true);
						props.setSelectedProoerty({ id: params.id, data: params.row });
					}}
				>
					<EditOutlined /> <p className="m-0">Edit</p>
				</MenuItem>

				<MenuItem
					onClick={() => {
						props.deleteProject(params.id);
					}}
				>
					<Delete /> <p className="m-0">Delete</p>
				</MenuItem>
			</BurgerMenu>,
		],
	},
];

export const Property = () => {
	const dispatch = useDispatch();
	const [addNewProperty, setAddNewProperty] = useState(false);
	const [editProperty, setEditProperty] = useState(false);
	const [selectedProperty, setSelectedProperty] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);

	const { propertiesList, totalCount } = useSelector(getProperties);

	const navigate = useNavigate();

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });

	useEffect(() => {
		dispatch(propertyGetAll());
	}, [dispatch]);

	console.log("Check the properties : ", propertiesList, totalCount);

	return addNewProperty ? (
		<AddNewProperty
			onCancel={() => {
				setAddNewProperty(false);
				setEditProperty(null);
				setSelectedProperty(null);
			}}
			jobId={editProperty}
			selectedProject={selectedProperty}
			title={get(selectedProperty, "data.propertyName", null)}
		/>
	) : (
		<>
			<div className="propertiesList-header-section">
				<div>
					<Typography className="h1">Properties</Typography>
					<BreadCrumb />
				</div>
			</div>
			<div className="propertiesList-content-section">
				<DataGridTable
					columns={columns({
						setEditProperty,
						setAddNewProperty,
						setSelectedProperty,
						deleteProject: () => {},
						// deleteProperty: (id) => {
						// 	console.log("Delete Property : ", id);
						// 	dispatch(deletePropertyById(id, pageModel));
						// },
						navigate: navigate,
						// setUserManagement,
					})}
					data={propertiesList || []}
					defineRowId={(row) => {
						return row.propertyId;
					}}
					pagination={totalCount > 10}
					page
					totalCount={totalCount}
					paginationModel={pageModel}
					onPaginationChange={(props) => {
						console.log("Pagination Props : ", props);
						setPageModel(props);
					}}
					onSelect={(props) => {
						console.log("Selected Rows : ", props);
						setSelectedRows(props);
					}}
					selectedRows={selectedRows}
					onDeleteSelection={() => {
						console.log("Delete Selection : ", selectedRows);
					}}
					onRowClick={(row) => {
						// navigate(`/propertiesList/view/${row.id}`);
					}}
				/>
			</div>
			{!addNewProperty && (
				<div className="page-footer  fixed-footer align-content-center">
					<div className="d-flex justify-content-end ">
						<button
							className="primaryButton addProjectButton"
							style={{ width: "8rem" }}
							onClick={() => {
								setAddNewProperty(!addNewProperty);
							}}
						>
							{"+ Add Property"}
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default Property;
