import { getCompanies } from "actions/companiesActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companiesSelector } from "selectors/companiesSelectors";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";

const columns = [
	{ id: "companyId", label: "S.no", minWidth: 50 },
	{ id: "companyName", label: "Company Name", minWidth: 150 },
	{
		id: "companyType",
		label: "Type",
		minWidth: "auto",
	},
	{
		id: "marketSectors",
		label: "Sectors",
		minWidth: "auto",
	},
	{
		id: "suppliersAndServices",
		label: "Services",
		minWidth: "auto",
	},
	{
		id: "serviceAreas",
		label: "Service Areas",
		minWidth: "auto",
	},
];

export const CompaniesList = () => {
	const dispatch = useDispatch();
	const companiesList = useSelector(companiesSelector);

	console.log("Check the companiesList", companiesList);

	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<div>
			{" "}
			<div>
				<Typography variant="h6">Companies List</Typography>
			</div>
			<div className="projects-content-section">
				<Paper sx={{ width: "100%", overflow: "hidden" }} className="mt-5">
					<TableContainer sx={{ maxHeight: 440 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{companiesList
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.code}
											>
												{columns.map((column) => {
													const value = row[column.id];
													return (
														<TableCell key={column.id} align={column.align}>
															{column.format ? column.format(value) : value}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={companiesList.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
		</div>
	);
};

export default CompaniesList;
