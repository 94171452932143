import { appRoles } from "constants/appConstants";

export const isAdmin = (profile) => {
	return profile.roleName === "SystemAdmin";
};

export const isBuilderAdmin = (profile) => {
	console.log("CHeck is BuilderAdmin :", profile);
	return appRoles.builderAdmin.includes(profile.roleName);
};
export const isBuilderTeam = (profile) => {
	return appRoles.builderTeam.includes(profile.roleName);
};

export const isSupplierAdmin = (profile) => {
	return appRoles.supplierADmin.includes(profile.roleName);
};

export const isSupplierTeam = (profile) => {
	return appRoles.supplierTeam.includes(profile.roleName);
};

export const isHouseOwner = (profile) => {
	return appRoles.houseOwner.includes(profile.roleName);
};

export const isProjectManager = (appRole, projectRole) => {
	return (
		isBuilderTeam(appRole) ||
		appRoles.projectManager.includes(projectRole.roleName)
	);
};
