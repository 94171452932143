import React, { useContext, useEffect, useState } from "react";
import AddEditProject from "./AddNewProject";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteProjectById, fetchProjects } from "actions/projectActions";
import { getProjects } from "selectors/projectSelector";
import BreadCrumb from "components/BreadCrumb";
import DataGridTable from "components/Table/DataGrid";

import BurgerMenu from "components/Menu/BurgerMenu";
import {
	AssignmentOutlined,
	Delete,
	EditOutlined,
	VerifiedUser,
} from "@mui/icons-material";
import AddNewJob from "pages/job/AddNewJob";
import get from "lodash/get";
import { useNavigate } from "react-router-dom";
import { ModelDialog } from "components/Model";
import ManageUsers from "pages/users/ManageUsers";
import { projectStatusOptions } from "core/utils/appUtils";
import HiddenOn from "components/Wrappers/HiddenOn";
import AppRoleContext from "context/AppRoleContext";
import { isBuilderAdmin, isProjectManager } from "core/utils/roleUtils";

const columns = (props) => [
	{
		field: "projectId",
		headerName: "S.no",
		sortable: false,
		width: 50,
		resizable: false,
		renderCell: (rowData) => {
			return (
				<p
					className=""
					onClick={() =>
						props.navigate(`/projects/view/${rowData.row.projectId}`)
					}
				>
					{rowData.formattedValue}
				</p>
			);
		},
	},
	{
		field: "projectName",
		headerName: "Property Name",
		sortable: false,
		width: 200,
		resizable: false,
		renderCell: (rowData) => {
			return (
				<p
					className="link-text"
					onClick={() =>
						props.navigate(`/projects/view/${rowData.row.projectId}`)
					}
				>
					{rowData.formattedValue}
				</p>
			);
		},
	},
	{
		field: "customerName",
		headerName: "Customer",
		resizable: false,
		width: 200,
	},
	// {
	// 	field: "areaSqFt",
	// 	headerName: "Area",
	// 	resizable: false,
	// 	width: 100,
	// },
	{
		field: "stage",
		headerName: "Current Status",
		sortable: false,
		resizable: false,
		width: 100,
	},
	{
		field: "newStage",
		headerName: "Update Status",
		sortable: false,
		resizable: false,
		width: 150,
		renderCell: (rowData) => {
			return (
				<TextField
					className="form-field update-status-field"
					select
					value={rowData.row.stage}
				>
					{projectStatusOptions.map((option) => (
						<MenuItem value={option}>
							<Typography>{option}</Typography>
						</MenuItem>
					))}
				</TextField>
			);
		},
	},
	{
		field: "projectCostEstimated",
		headerName: "Estimation",
		resizable: false,
		width: 100,
		align: "right",
		renderCell: (rowData) => {
			return `$ ${rowData.formattedValue}`;
		},
	},
	{
		field: "startDate",
		headerName: "Start Date",
		width: 100,
	},
	{
		field: "siteLocation",
		headerName: "Location",
		width: 100,
		renderCell: (rowData) => {
			return (
				<p className="link-text">
					<a href={rowData.formattedValue} target="_blank" rel="noreferrer">
						{"View Location"}
					</a>
				</p>
			);
		},
		sortable: false,
	},
	{
		field: "actions",
		type: "actions",
		width: 100,
		align: "left",
		getActions: (params) => [
			<BurgerMenu>
				<HiddenOn
					isHidden={!isProjectManager(props.appRoleDetails, params.row.project)}
				>
					<MenuItem
						onClick={() => {
							props.setEditProject(true);
							props.setAddNewProject(true);
							props.setSelectedProject({ id: params.id, data: params.row });
						}}
					>
						<EditOutlined /> <p className="m-0">Edit</p>
					</MenuItem>
				</HiddenOn>
				<MenuItem
					onClick={() => {
						console.log("Check the Params : ", params);
						props.setAddJob(true);
						props.setSelectedProject({
							id: params.id,
							data: params.row,
						});
					}}
				>
					<AssignmentOutlined /> <p className="m-0">Add Job</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.deleteProject(params.id);
					}}
				>
					<Delete /> <p className="m-0">Delete</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.setUserManagement(true);
					}}
				>
					<VerifiedUser />
					Manage Team
				</MenuItem>
			</BurgerMenu>,
		],
	},
];

export const Projects = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [addNewProject, setAddNewProject] = useState(false);
	const { projectsList, totalCount } = useSelector(getProjects);

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [editProject, setEditProject] = useState(false);
	const [addJob, setAddJob] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [userManagement, setUserManagement] = useState(false);

	const appRoleDetails = useContext(AppRoleContext);

	useEffect(() => {
		dispatch(fetchProjects(pageModel));
	}, [dispatch, pageModel]);

	if (addJob) {
		return (
			<AddNewJob
				onCancel={() => setAddJob(false)}
				parentId={{ projectId: selectedProject.id }}
			/>
		);
	}

	return (
		<div className="projects-page">
			<ModelDialog
				fullWidth
				open={userManagement}
				handleClose={() => {
					setUserManagement(false);
				}}
				dialogTitle=""
				dialogContent={<ManageUsers />}
			/>
			{addNewProject ? (
				<AddEditProject
					onCancel={() => {
						setAddNewProject(false);
						setEditProject(null);
						setSelectedProject(null);
					}}
					jobId={editProject}
					selectedProject={selectedProject}
					title={get(selectedProject, "data.projectName", null)}
				/>
			) : (
				<>
					<div className="projects-header-section">
						<div>
							<Typography className="h1">Projects</Typography>
							<BreadCrumb />
						</div>
					</div>
					<div className="projects-content-section">
						<DataGridTable
							columns={columns({
								setEditProject,
								setAddJob,
								setAddNewProject,
								setSelectedProject,
								deleteProject: (id) => {
									console.log("Delete Project : ", id);
									dispatch(deleteProjectById(id, pageModel));
								},
								navigate: navigate,
								setUserManagement,
								appRoleDetails,
							})}
							data={projectsList}
							defineRowId={(row) => {
								return row.projectId;
							}}
							pagination={totalCount > 10}
							page
							totalCount={totalCount}
							paginationModel={pageModel}
							onPaginationChange={(props) => {
								console.log("Pagination Props : ", props);
								setPageModel(props);
							}}
							onSelect={(props) => {
								console.log("Selected Rows : ", props);
								setSelectedRows(props);
							}}
							selectedRows={selectedRows}
							onDeleteSelection={() => {
								console.log("Delete Selection : ", selectedRows);
							}}
							onRowClick={(row) => {
								// navigate(`/projects/view/${row.id}`);
							}}
						/>
					</div>
				</>
			)}
			{console.log("Check the app ROle : ", appRoleDetails)}
			<HiddenOn isHidden={!isBuilderAdmin(appRoleDetails)}>
				{!addNewProject && (
					<div className="page-footer  fixed-footer align-content-center">
						<div className="d-flex justify-content-end ">
							<button
								className="primaryButton addProjectButton"
								style={{ width: "8rem" }}
								onClick={() => {
									setAddNewProject(!addNewProject);
								}}
							>
								{"+ Add Project"}
							</button>
						</div>
					</div>
				)}
			</HiddenOn>
		</div>
	);
};

export default Projects;
