import { makeStyles } from "@mui/styles";
import { palette } from "themes";

export default makeStyles((theme) => ({
	link: {
		margin: "0.5rem",
		height: "3rem",
		color: "white",
		textDecoration: "none",
		"&:hover, &:focus": {
			backgroundColor: palette.bluePrimary,
		},
	},
	linkActive: {
		borderRadius: "5px",
		backgroundColor: palette.bluePrimary,
		color: "white",
	},
	linkNested: {
		paddingLeft: 0,
	},
	linkIcon: {
		marginRight: theme.spacing(0),
		color: theme.palette.text.secondary + "99",
		transition: theme.transitions.create("color"),
		width: 24,
		display: "flex",
		justifyContent: "center",
	},
	linkIconActive: {
		color: theme.palette.primary.main,
	},
	linkText: {
		padding: 0,
		color: "white",

		transition: theme.transitions.create(["opacity", "color"]),
		fontSize: 16,
	},

	linkTextHidden: {
		opacity: 0,
	},
	nestedList: {
		paddingLeft: theme.spacing(2) + 30,
	},
	sectionTitle: {
		marginLeft: theme.spacing(4.5),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(4),
		height: 1,
		backgroundColor: "#D8D8D880",
	},
	tooltip: {
		position: "fixed",
		left: "5rem", // Adjust based on your collapsed sidebar width
		backgroundColor: "lightgray",
		padding: theme.spacing(1),
		zIndex: 1000,
		width: "200px",
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[2],
	},
}));
