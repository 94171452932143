import moment from "moment";

export const calculateHours = (start, end) => {
	const startDate = new Date(start);
	const endDate = new Date(end);
	const diffMs = endDate - startDate;
	const diffHours = diffMs / (1000 * 60 * 60);
	return Math.round(diffHours * 100) / 100; // Round to 2 decimal places
};

// export const calculateHours = (startDate, endDate) => {
// 	const start = moment(startDate);
// 	const end = moment(endDate);
// 	const diffHours = end.diff(start, "hours", true);
// 	diffHours.toFixed(2);
// };

// export const calculateEndDate = (start, hours) => {
// 	const startDate = moment(start);
// 	const endDate = startDate.clone().add(hours, "hours");
// 	return endDate.toDate();
// };

export const calculateEndDate = (start, hours) => {
	const startDate = new Date(start);
	const endDate = new Date(startDate.getTime() + hours * 60 * 60 * 1000);
	return endDate;
};
