import React, { Component } from "react";
import { connect } from "react-redux";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import moment from "moment";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Snackbar,
} from "@mui/material";

import Alert from "@mui/material/Alert";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import InvoiceToolbar from "./InvoiceToolbar";
import LabTabs from "./LabTabs";
import LinearIndeterminate from "./LinearIndeterminate";

import {
  invoiceGetAll,
  invoiceDelete,
  invoiceAddOrUpdate,
  invoiceClearShowSnackbar,
} from "../../actions/invoiceActions";

const components = {
  ExpandButton: function (props) {
    return <ExpandButton {...props} />;
  },
};

const MyAlert = (props) => {
  return <Alert elevation={6} variant="filled" {...props} />;
};

class INVOICE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceList: [],
      columns: [
        {
          name: "INVOICE_ID",
          label: "INVOICE_ID",
          options: {
            sort: false,
            display: true,
          },
        },
        {
          name: "INVOICE_NUMBER",
          label: "INVOICE_NUMBER",
          options: {
            sort: false,
            display: true,
          },
        },
        {
          name: "INVOICE_DATE",
          label: "INVOICE_DATE",
          options: {
            sort: false,
            display: true,
          },
        },
        {
          name: "AMOUNT",
          label: "AMOUNT",
          options: {
            sort: false,
            display: true,
          },
        },
        {
          name: "PAID_AMOUNT",
          label: "PAID_AMOUNT",
          options: {
            sort: false,
            display: true,
          },
        },
        {
          name: "PAID_DATE",
          label: "PAID_DATE",
          options: {
            sort: false,
            display: true,
          },
        },
        {
          name: "InvoiceStatus.STATUS_NAME",
          label: "Status",
          options: {
            sort: false,
            display: true,
          },
        },
        {
          name: "Job.NAME",
          label: "Job",
          options: {
            sort: false,
            display: true,
          },
        },

        {
          name: "Action",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <IconButton
                    style={{ width: "50%" }}
                    onClick={() =>
                      this.handleDelete(this.state.invoiceList[dataIndex])
                    }
                  >
                    <DeleteIcon color="secondary" />
                  </IconButton>
                  <IconButton
                    style={{ width: "50%" }}
                    onClick={() => this.handleEdit(dataIndex)}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
          },
        },
      ],
      showEditModal: false,
      newINVOICE: {
        INVOICE_ID: 0,
        INVOICE_NUMBER: "",
        INVOICE_DATE: "",
        AMOUNT: "",
        PAID_AMOUNT: "",
        PAID_DATE: "",
        INVOICE_STATUS_ID: "",
        JOB_ID: "",
      },
      showConfirmModal: false,
      allowSubmit: false,
      state: "",
      status: "",
      statusText: "",
      loading: false,
      showSnackbar: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.invoiceGetAll();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      invoiceList: nextProps.invoice.invoiceList,
      status: nextProps.invoice.status,
      statusText: nextProps.invoice.text,
      loading: nextProps.invoice.loading,
      showSnackbar: nextProps.invoice.showSnackbar,
    });
  }

  fireModal = () => {
    this.setState({
      showEditModal: true,
      newINVOICE: {
        INVOICE_ID: 0,
        INVOICE_NUMBER: "",
        INVOICE_DATE: "",
        AMOUNT: "",
        PAID_AMOUNT: "",
        PAID_DATE: "",
        INVOICE_STATUS_ID: "",
        JOB_ID: "",
      },
      state: "add",
      deleteINVOICE: {},
      editINVOICE: {},
    });
  };

  handleClose = () => {
    this.setState({
      showEditModal: false,
      state: "",
    });
  };

  handleSubmit = () => {
    this.props.invoiceAddOrUpdate(this.state.newINVOICE, this.state.state);
    this.setState({
      showEditModal: false,
      allowSubmit: false,
      state: "",
    });
  };

  handleDelete = (invoice) => {
    console.log("employ!!!!!!!!", invoice);
    this.setState({
      deleteINVOICE: invoice,
      showConfirmModal: true,
    });
  };

  handleEdit = (index) => {
    let {
      INVOICE_ID: INVOICE_ID,
      INVOICE_NUMBER: INVOICE_NUMBER,
      INVOICE_DATE: INVOICE_DATE,
      AMOUNT: AMOUNT,
      PAID_AMOUNT: PAID_AMOUNT,
      PAID_DATE: PAID_DATE,
      INVOICE_STATUS_ID: INVOICE_STATUS_ID,
      JOB_ID: JOB_ID,
    } = this.state.invoiceList[index];
    let invoice = {
      INVOICE_ID: INVOICE_ID,
      INVOICE_NUMBER: INVOICE_NUMBER,
      INVOICE_DATE: INVOICE_DATE,
      AMOUNT: AMOUNT,
      PAID_AMOUNT: PAID_AMOUNT,
      PAID_DATE: PAID_DATE,
      INVOICE_STATUS_ID: INVOICE_STATUS_ID,
      JOB_ID: JOB_ID,
    };
    this.setState(
      {
        showEditModal: true,
        newINVOICE: invoice,
        state: "edit",
      },
      () => {
        console.log("this is new invoice", this.state.newINVOICE);
      },
    );
  };

  setNewINVOICE = (name, value) => {
    let newEmp = this.state.newINVOICE;
    newEmp[name] = value;
    this.setState(
      {
        newINVOICE: newEmp,
      },
      () => {
        console.log("newemploy!!!!!!!!!!!!", this.state.newINVOICE);
        if (this.isValidate(this.state.newINVOICE)) {
          this.setState({
            allowSubmit: true,
          });
        }
      },
    );
  };

  handleCloseConfirm = () => {
    this.setState({
      deleteINVOICE: {},
      showConfirmModal: false,
    });
  };

  handelDeleteINVOICE = () => {
    this.props.invoiceDelete(this.state.deleteINVOICE);
    this.setState({
      deleteINVOICE: {},
      showConfirmModal: false,
    });
  };

  isValidate = (invoice) => {
    if (
      invoice.INVOICE_NUMBER &&
      invoice.INVOICE_DATE &&
      invoice.AMOUNT &&
      invoice.INVOICE_STATUS_ID &&
      invoice.JOB_ID
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
    });
    this.props.invoiceClearShowSnackbar();
  };

  render = () => (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.state.showSnackbar}
        autoHideDuration={6000}
        onClose={this.handleCloseSnackbar}
      >
        <Alert onClose={this.handleCloseSnackbar} severity={this.state.status}>
          {this.state.statusText}
        </Alert>
      </Snackbar>
      {this.state.loading && <LinearIndeterminate />}
      <PageTitle title="INVOICE" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title="INVOICE List"
            data={this.state.invoiceList}
            columns={this.state.columns}
            options={{
              rowHover: true,
              selectableRows: "none",
              viewColumns: false,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 15],
              enableNestedDataAccess: ".",
              customToolbar: () => {
                return <InvoiceToolbar fireModal={this.fireModal} />;
              },
              filter: false,
              filterType: "dropdown",
              responsive: "standard",
              expandableRows: true,
              expandableRowsOnClick: false,
              isRowExpandable: (dataIndex, expandedRows) => {
                return true;
              },

              renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1;
                return (
                  <>
                    <TableRow>
                      <TableCell colSpan={colSpan}></TableCell>
                    </TableRow>
                  </>
                );
              },
            }}
            components={components}
          />
        </Grid>
      </Grid>
      <Dialog
        open={this.state.showEditModal}
        onClose={this.handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">INVOICE</DialogTitle>
        <DialogContent>
          <LabTabs
            setNewINVOICE={this.setNewINVOICE}
            editINVOICE={this.state.newINVOICE}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            disabled={!this.state.allowSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.showConfirmModal}
        onClose={this.handleCloseConfirm}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <Typography>Do you want to delete this invoice info?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handelDeleteINVOICE} color="primary">
            Sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceGetAll: () => {
      dispatch(invoiceGetAll());
    },
    invoiceDelete: (invoice) => {
      dispatch(invoiceDelete(invoice));
    },
    invoiceAddOrUpdate: (invoice, state) => {
      dispatch(invoiceAddOrUpdate(invoice, state));
    },
    invoiceClearShowSnackbar: () => {
      dispatch(invoiceClearShowSnackbar());
    },
  };
};

const mapStateToProps = (state) => ({
  invoice: state.invoice,
});

export default INVOICE = connect(mapStateToProps, mapDispatchToProps)(INVOICE);
