import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.company;

export const companiesLoader = createSelector(selectState, (companies) =>
	get(companies, "loading", false)
);

export const companiesSelector = createSelector(selectState, (companies) =>
	get(companies, "companiesList", [])
);
