import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { fetchJobById, jobGetAll } from "actions/jobActions";
import { taskGetAll } from "actions/taskActions";
import DataGridTable from "components/Table/DataGrid";
import { convertCamelToNormal } from "core/utils/appUtils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getjobData } from "selectors/jobSelectors";
import { getTasks } from "selectors/taskSelectors";

export const ViewJob = ({ job }) => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const jobData = useSelector(getjobData);
	const { tasksList } = useSelector(getTasks);

	useEffect(() => {
		dispatch(fetchJobById(params.jobId));
		dispatch(taskGetAll({ jobId: params.jobId }));
	}, [dispatch, params.jobId]);

	return (
		<>
			<div className="d-flex justify-content-between">
				<Typography className="h1 mt-3">
					{jobData.jobName || "View Project Name"}
				</Typography>
				<IconButton>
					<Close onClick={() => navigate("/projects")} />
				</IconButton>
			</div>
			<div className="m-3 mt-5 mb-5">
				<Typography className="h2">Project Details:</Typography>
				<Typography className="body1">{jobData.projectDetails}</Typography>
			</div>
			<div className="d-flex flex-wrap">
				{Object.keys(jobData).map((key) => {
					return (
						<div className="m-3 col-3">
							<Typography className="body1">
								<strong>{convertCamelToNormal(key)}</strong>
							</Typography>
							<Typography style={{ wordBreak: "all" }} className="body1">
								{key === "siteLocation" ? (
									<a
										href={jobData[key]}
										target="_blank"
										rel="noreferrer"
										className="link-text"
									>
										View Location
									</a>
								) : (
									jobData[key]
								)}
							</Typography>
						</div>
					);
				})}
			</div>
			<div>
				<div className="" style={{ width: "50%" }}>
					<div className="p-3">
						<Typography className="h3">Tasks</Typography>
						<DataGridTable
							width={"100%"}
							columns={[
								{
									field: "header",
									headerName: "Job Name",
									width: 150,
									sortable: false,
									editable: false,
									resizable: false,
								},
								{
									field: "job",
									headerName: "Job Name",
									width: 150,
									sortable: false,
									editable: false,
									resizable: false,
									renderCell: (params) => {
										return params.row.job.jobName;
									},
								},
								{
									field: "taskStatus",
									headerName: "Task Status",
									width: 150,
									sortable: false,
									editable: false,
									resizable: false,
									renderCell: (params) => {
										return params.row.taskStatus || "Not Known";
									},
								},
							]}
							data={tasksList}
							checkboxSelection={false}
							loading={false}
							pageSize={10}
							showToolbar={false}
							pagination={false}
							rowsPerPageOptions={[10, 25, 50, 100]}
							defineRowId={(row) => row.jobId}
							onRowClick={(row) => navigate(`/tasks/view/${row.row.taskId}`)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewJob;
