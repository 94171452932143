import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

// used react-dropzone for file upload for Future reference and documentation please refer https://react-dropzone.js.org/#src

/*
FileType Format: 
or
  accept: {
    'image/*': ['.jpg', '.jpeg', '.png'],
    'image/png': ['.png'],
    'text/html': ['.html', '.htm'],
  }

*/
export const FileUpload = ({
	children,
	setFiles,
	multiple,
	accept,
	className,
	dropZoneProps = {},
}) => {
	const onDrop = useCallback(
		(acceptedFiles) => {
			acceptedFiles.forEach((file) => {
				const reader = new FileReader();

				reader.onabort = () => console.log("file reading was aborted");
				reader.onerror = () => console.log("file reading has failed");
				reader.onload = () => {
					// Do whatever you want with the file contents
					const binaryStr = reader.result;
					console.log(binaryStr);
				};
				reader.readAsArrayBuffer(file);
			});
			const browsedFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			);
			console.log({ browsedFiles }, browsedFiles[0]);
			setFiles(browsedFiles);
		},
		[setFiles]
	);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept,
		multiple: multiple,
		...dropZoneProps,
	});

	return (
		<>
			<div
				{...getRootProps({
					className: `${className} dropzone`,
					style: { display: "block" },
					role: "button",
				})}
			>
				<input {...getInputProps()} id="uploadResume" />
				{console.log("CHeck the Children : ", children)}
				{children ? (
					children
				) : (
					<>
						<p className="text-center upload-dropZone-text">
							Drag 'n' drop some files here, or click to select files
						</p>
					</>
				)}
			</div>
		</>
	);
};

export default FileUpload;
