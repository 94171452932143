import React from "react";
import "./underDevelopment.scss";

const UnderConstruction = () => {
	return (
		<div className="under-construction">
			<div className="content">
				<h1>🚧 Under Construction 🚧</h1>
				<p>We're working hard to bring you something amazing!</p>
				<div className="progress-bar">
					<div className="progress"></div>
				</div>
				<p className="launch-message">Launching soon...</p>
			</div>
		</div>
	);
};

export default UnderConstruction;
