import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import queryString from "query-string";

export const getCompanies = (params) => {
	return (dispatch, _) => {
		const query = queryString.stringify(params);
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_COMPANIES,
				payload: {
					loading: true,
				},
			});
			request({ method: "GET", url: `${server_url}/company?${query}` })
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_COMPANIES_SUCCESS,
						payload: {
							companiesList: response.data.items,
							totalCount: response.data.totalCount,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_COMPANIES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
