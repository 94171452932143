import {
	Autocomplete,
	CircularProgress,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { fetchUsers } from "actions/userActions";
import { FormLabel } from "components/TextFields/FormInputField";
import { projectRoles } from "constants/appConstants";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList, getUsersLoading } from "selectors/userSelector";

export const ManageUsers = (props) => {
	const dispatch = useDispatch();
	const usersList = useSelector(getUsersList);
	let usersLoading = useSelector(getUsersLoading);
	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState(null);
	const [selectedRole, setSelectedRole] = React.useState(null);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const handleInputChange = (event, newInputValue) => {
		// setInputValue(newInputValue);
		if (newInputValue === "" && event && event.type === "click") {
			console.log("Clear icon clicked - handleInputChange");
		}
	};
	return (
		<div>
			<div>
				<Typography className="h4 mb-4">
					{props.headerTitle || "Manage Team"}
				</Typography>
			</div>
			<div className="d-flex flex-wrap">
				<div className="m-2 col-md-4 col-12">
					<FormLabel text={"Select User"} className={"mb-3"} />
					<Autocomplete
						onChange={(event, value) => {
							setSelectedUser(value);
						}}
						onInputChange={handleInputChange}
						id="asynchronous-demo"
						className="form-field"
						open={autoCompleteOpen}
						onOpen={() => {
							setAutoCompleteOpen(true);
						}}
						onClose={() => {
							setAutoCompleteOpen(false);
							console.log("Check on Close ");
						}}
						isOptionEqualToValue={(option, value) =>
							option.userId === value.userId
						}
						// filterOptions={(x) => x}
						getOptionLabel={(option) => option.firstName}
						options={usersList}
						loading={usersLoading}
						renderInput={(params) => (
							<TextField
								{...params}
								className="form-field"
								onChange={(event, value) => {
									console.log("On Text Field : ", event.target.value);
									const textLen = event.target.value.length;
									if (textLen >= 3) {
										console.log("Fetching the record");
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{usersLoading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</div>
				<div className="m-2 col-md-4 col-12">
					<FormLabel text={"Select Role"} className={"mb-3"} />
					<TextField
						className={`form-field ${selectedUser ? "" : "disabled"}`}
						select
						disabled={!selectedUser}
					>
						<MenuItem value={"Select Role"} disabled>
							Select Role
						</MenuItem>
						{projectRoles.map((role) => {
							return (
								<MenuItem value={role}>
									<Typography>{role}</Typography>
								</MenuItem>
							);
						})}
					</TextField>
				</div>
				<div className="d-flex align-items-center mt-sm-4 mt-3 col-md-2 col-2">
					<button className="secondaryButton">
						{props.buttonText || "Add User"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManageUsers;
