import React, { useState, useRef } from "react";
import {
	Collapse,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toggleSidebar, useLayoutDispatch } from "context/LayoutContext";
import Dot from "../Dot";

export default function SidebarLink(props) {
	const [isOpen, setIsOpen] = useState(false);

	const classes = useStyles();
	const layoutDispatch = useLayoutDispatch();
	const linkRef = useRef(null);

	if (props.type === "title") {
		return (
			<Typography
				className={classnames(classes.linkText, classes.sectionTitle, {
					[classes.linkTextHidden]: !props.isSidebarOpened,
				})}
			>
				{props.label}
			</Typography>
		);
	}

	if (props.type === "divider") return <Divider className={classes.divider} />;

	const getTooltipLabel = () => {
		if (props.isSidebarOpened) {
			return "";
		}
		return props.children ? "" : props.label;
	};

	return (
		<Tooltip title={getTooltipLabel()}>
			<div
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
			>
				<RenderListItem
					{...props}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					layoutDispatch={layoutDispatch}
					ref={linkRef}
				/>
				{props.hoverIndex === props.index &&
					props.children &&
					props.children.length > 0 && (
						<div
							style={{
								backgroundColor: "black",
								width: "300px",
								height: "200px",
								position: "absolute",
								left: "5rem",
							}}
						></div>
					)}

				{props.children && props.children.length > 0 && (
					<>
						<Collapse
							in={isOpen}
							timeout="auto"
							unmountOnExit
							className={classes.nestedList}
						>
							{props.children.map((childLink, cIndex) => (
								<SidebarLink
									key={childLink.link || cIndex}
									{...childLink}
									nested={true}
									isSidebarOpened={props.isSidebarOpened}
									setHoverIndex={props.setHoverIndex}
									index={cIndex}
								/>
							))}
						</Collapse>
					</>
				)}
			</div>
		</Tooltip>
	);
}

const RenderListItem = ({
	link,
	icon,
	label,
	children,
	isSidebarOpened,
	nested,
	desktopMenu,
	onMenuClick,
	isOpen,
	setIsOpen,
	layoutDispatch,
}) => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const isLinkActive =
		link &&
		(location.pathname === link || location.pathname.indexOf(link) !== -1);

	function toggleCollapse(e) {
		if (link) {
			e.preventDefault();
		}
		setIsOpen(!isOpen);
		if (!desktopMenu) {
			toggleSidebar(layoutDispatch);
		}
	}

	return (
		<ListItem
			key={link}
			component={link ? Link : "div"}
			onClick={(e) => {
				navigate(link);
				if (onMenuClick) onMenuClick();
				toggleCollapse(e);
			}}
			className={classnames(classes.link, {
				[classes.linkActive]: isLinkActive && !nested,
				// [classes.linkNested]: nested,
			})}
			to={link}
			disableRipple
		>
			<ListItemIcon
				className={classnames(classes.linkIcon, {
					[classes.linkIconActive]: isLinkActive,
				})}
			>
				{icon ? icon : <Dot color={isLinkActive ? "primary" : undefined} />}
			</ListItemIcon>
			{isSidebarOpened && (
				<ListItemText
					classes={{
						primary: classnames(classes.linkText, {
							[classes.linkTextActive]: isLinkActive,
						}),
					}}
					primary={label}
				/>
			)}
			{children &&
				children.length > 0 &&
				(isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
		</ListItem>
	);
};
