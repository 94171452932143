import { DeleteOutline } from "@mui/icons-material";
import { MenuItem, Typography } from "@mui/material";
import { fetchUsers } from "actions/userActions";
import BurgerMenu from "components/Menu/BurgerMenu";
import DataGridTable from "components/Table/DataGrid";
import ManageUsers from "pages/users/ManageUsers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList } from "selectors/userSelector";

export const UserManagement = () => {
	const dispatch = useDispatch();
	const usersList = useSelector(getUsersList);
	const [newUserModel, setNewUserModel] = useState(false);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 100,
		},
		{
			field: "firstName",
			headerName: "First Name",
			width: 200,
			valueFormatter: (_, row) => {
				return `${row.firstName} ${row.lastName}`;
			},
			// ,
		},
		{
			field: "email",
			headerName: "Email",
			width: 200,
		},
		{
			field: "mobileNumber",
			headerName: "Contact Number",
			width: 200,
		},
		{
			field: "userType",
			headerName: "Role",
			width: 200,
		},
		{
			field: "actions",
			type: "actions",
			align: "left",
			getActions: (params) => [
				<BurgerMenu>
					<MenuItem
						onClick={() => {
							console.log("Check the Params : ", params);
						}}
					>
						<DeleteOutline /> <p className="m-0">Remove User</p>
					</MenuItem>
				</BurgerMenu>,
			],
		},
	];

	return (
		<>
			<div className="user-list-container">
				<div>
					<ManageUsers
						buttonText="Add to Team"
						headerTitle="Add members to team"
					/>
				</div>
				<div className="user-list-header d-flex justify-content-between mt-4">
					<div>
						<Typography className="h1">Team</Typography>
						<Typography className="body1">
							List of all team members associated with this Project
						</Typography>
					</div>
					<div className="align-self-center">
						<button
							className="primaryButton mt-3"
							onClick={() => setNewUserModel(true)}
						>
							Add New User
						</button>
					</div>
				</div>
				<div className="user-list-section mt-3 p-3">
					<DataGridTable
						columns={columns}
						data={usersList}
						defineRowId={(row) => row.userId}
						checkboxSelection={false}
						showToolbar={false}
					/>
				</div>
			</div>
		</>
	);
};

export default UserManagement;
