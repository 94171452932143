import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Typography, IconButton, FormHelperText } from "@mui/material";
import { addProjectForm, addProjectFields } from "./addProjectUtils";
import get from "lodash/get";
import { LocationField } from "components/Location/GeoLocation";
import { CloseOutlined } from "@mui/icons-material";
import "./projects.scss";
import FormInputField, {
	FormLabel,
} from "components/TextFields/FormInputField";
import DatePickerField from "components/DateComponets/DatePicker";
import { addNewProject } from "actions/projectActions";
import FileUpload from "components/upload/FileUpload";

export const AddNewProperty = ({
	onCancel,
	projectId,
	selectedProject = {},
	title,
}) => {
	const dispatch = useDispatch();

	const [selectedLogo, setSelectedLogo] = useState(null);
	const [selectedPhotos, setSelectedPhotos] = useState(null);

	const { validationSchema } = addProjectForm;
	const initialState = addProjectForm.defaultState(
		get(selectedProject, "data", null)
	);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			console.log("Check the Onsubmit : ", formik.values);
			dispatch(addNewProject(formik.values, onCancel));
		},
	});

	const removeFile = (index) => {
		setSelectedPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
	};

	return (
		<div className="mt-0 p-1">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h1" id="header">
					{title || "Create New Project"}
				</Typography>
				<IconButton onClick={() => onCancel()}>
					<CloseOutlined />
				</IconButton>
			</div>
			<div id="create-project" className="mt-5">
				<form onSubmit={formik.handleSubmit}>
					<div id="container row">
						<div id="section-1 mt-3">
							<div id="section-header">
								<Typography className="text-left m-2 h2">
									Project Information
								</Typography>
							</div>
							<div className="d-flex">
								<div className="form-fields col-12 col-md-7">
									<div className="container row justify-content-between">
										{addProjectFields.porpertyInformation.map((field) => {
											const extraProps = get(field, "extraInputProps", {});
											return field.type === "dateField" ? (
												<div
													className={`form-field-wrapper ${field.className}`}
													style={{ width: field.width }}
												>
													<FormLabel
														text={field.label}
														required={field.required}
													/>
													<DatePickerField
														onChange={(date) => {
															formik.setFieldValue(field.key, date);
														}}
														value={formik.values[field.key]}
														field={field}
													/>
												</div>
											) : (
												<FormInputField
													field={field}
													formik={formik}
													value={formik.values[field.key]}
													key={field.key}
													{...extraProps}
												/>
											);
										})}
									</div>
								</div>
								<div className="media-upload col-12 col-md-5">
									<div className="form-field-wrapper project-logo-upload">
										<label className="form-label">Project Logo</label>
										<div className="project-logo-upload-area">
											<FileUpload
												multiple={false}
												accept={{
													"image/*": [],
												}}
												setFiles={(files) => {
													console.log("Files Uploaded : ", files);
													setSelectedLogo(files[0]);
												}}
												children={
													<div className="text-center upload-dropZone-text">
														Upload Project Logo
													</div>
												}
												className={
													"project-logo-upload-area align-content-center"
												}
												dropZoneProps={{ maxFiles: 1 }}
											>
												{selectedLogo && (
													<img
														style={{ width: "100%", height: "90%" }}
														src={selectedLogo.preview}
														alt="logo"
													/>
												)}
											</FileUpload>
										</div>
									</div>
									<div className="form-field-wrapper project-photos-upload">
										<label className="form-label">Project Photos</label>
										<div className="project-photos-upload-area">
											<FileUpload
												multiple={true}
												setFiles={(files) => {
													console.log("Photos Uploaded : ", files.browsedFiles);
													setSelectedPhotos(files);
												}}
												accept={{
													"image/*": [],
												}}
												className={
													"project-photos-upload-area align-content-center"
												}
											>
												<div className="d-flex flex-wrap">
													{console.log("Check the Photos : ", selectedPhotos)}
													{selectedPhotos &&
														selectedPhotos.length > 0 &&
														selectedPhotos.map((photo, index) => {
															return (
																<div
																	key={index}
																	className="project-photo d-flex flex-wrap"
																>
																	<img
																		style={{
																			height: "50px",
																			width: "50px",
																			objectFit: "cover",
																		}}
																		src={photo.preview}
																		alt={photo.path}
																	/>
																	<button
																		className="remove-file-btn"
																		onClick={(e) => {
																			e.stopPropagation();
																			removeFile(index);
																		}}
																	>
																		×
																	</button>
																</div>
															);
														})}
												</div>
											</FileUpload>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="section-2" className="mt-5">
							<div id="section-header" className="mt-1">
								<Typography className="text-left m-2 h2">
									Project Location
								</Typography>
							</div>
							<div className="container row">
								<div
									className={`form-field-wrapper google-location-field form-field`}
									style={{ width: "100%" }}
								>
									<FormLabel text="Address Location" required={true} />
									<LocationField
										defaultAddress={formik.values.address1}
										onSelect={(payload) => {
											console.log("Check the payuload ", payload);
											const addressObj = payload.addressObj;
											formik.setFieldValue("siteLocation", payload.locationUrl);
											formik.setFieldValue("fullAddress", payload.locationUrl);
											formik.setFieldValue("latitude", payload.latLng.lat);
											formik.setFieldValue("longitude", payload.latLng.lng);
											for (let key in addressObj) {
												formik.setFieldValue(key, addressObj[key]);
											}
											// formik.setFieldValue("locationUrl", payload.locationUrl);
										}}
									/>
									{formik.errors.siteLocation && (
										<FormHelperText required className="Mui-error">
											{formik.errors.siteLocation}
										</FormHelperText>
									)}
								</div>
							</div>

							<div className="form-fields col-12 col-xs-6">
								<div className="container row justify-content-between">
									{addProjectFields.projectLocation.map((field) => {
										const extraProps = get(field, "extraInputProps", {});
										return (
											<FormInputField
												field={field}
												formik={formik}
												value={formik.values[field.key]}
												key={field.key}
												{...extraProps}
											/>
										);
									})}
								</div>
							</div>
						</div>
						{/* <div id="section-3">
							<div id="section-header">
								<Typography className="text-left m-2 h2">
									Additional Information
								</Typography>
							</div>
							<div className="form-fields col-12 col-md-6"></div>
						</div> */}
						<div className="mt-5">
							<div className="d-flex justify-content-center align-self-center">
								<button
									type="button"
									className={`secondaryButton m-3`}
									onClick={() => {
										onCancel();
									}}
								>
									{"Cancel"}
								</button>
								<button type="submit" className={`primaryButton m-3`}>
									{"Confirm"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddNewProperty;
