import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";

export const saveBusinessProfile = (formData, onSuccess) => {
	return (dispatch, _) => {
		console.log("IN Action ");
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.BUSINESS_PROFILE,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/company`,
				body: {
					...formData,
					serviceAreas: formData.serviceAreas.join(","),
					ServiceAreaStates: "",
				},
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.BUSINESS_PROFILE_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Profile Updated Successfully",
							loading: false,
						},
					});
					onSuccess({
						status: "success",
						message: "Company Data updated Successfully",
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.BUSINESS_PROFILE_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					onSuccess({
						status: "error",
						message: "Error in Saving Data, please try again",
					});

					resolve();
				});
		});
	};
};
