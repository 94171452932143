import { Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { fetchUsers } from "actions/userActions";
import BurgerMenu from "components/Menu/BurgerMenu";
import { ModelDialog } from "components/Model";
import DataGridTable from "components/Table/DataGrid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList } from "selectors/userSelector";

export const UsersList = () => {
	const dispatch = useDispatch();
	const usersList = useSelector(getUsersList);
	const [newUserModel, setNewUserModel] = useState(false);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 100,
		},
		{
			field: "firstName",
			headerName: "First Name",
			width: 200,
			valueFormatter: (_, row) => {
				return `${row.firstName} ${row.lastName}`;
			},
			// ,
		},
		{
			field: "email",
			headerName: "Email",
			width: 200,
		},
		{
			field: "mobileNumber",
			headerName: "Contact Number",
			width: 200,
		},
		{
			field: "userType",
			headerName: "Role",
			width: 200,
		},
		{
			field: "actions",
			type: "actions",
			align: "left",
			getActions: (params) => [
				<BurgerMenu>
					<MenuItem
						onClick={() => {
							console.log("Check the Params : ", params);
						}}
					>
						<DeleteOutline /> <p className="m-0">Remove User</p>
					</MenuItem>
				</BurgerMenu>,
			],
		},
	];

	return (
		<>
			<ModelDialog
				open={newUserModel}
				dialogContent={<AddNewUser setNewUserModel={setNewUserModel} />}
				handleClose={() => setNewUserModel(false)}
			/>
			<div className="user-list-container">
				<div className="user-list-header d-flex justify-content-between">
					<div>
						<Typography className="h1">Users</Typography>
						<Typography className="body1">
							Showing list of all the users associated with this account
						</Typography>
					</div>
					<div className="align-self-center">
						<button
							className="primaryButton mt-3"
							onClick={() => setNewUserModel(true)}
						>
							Add New User
						</button>
					</div>
				</div>
				<div className="user-list-section mt-3 p-3">
					<DataGridTable
						columns={columns}
						data={usersList}
						defineRowId={(row) => row.userId}
						checkboxSelection={false}
						showToolbar={false}
					/>
				</div>
			</div>
		</>
	);
};

export const AddNewUser = ({ setNewUserModel }) => {
	return (
		<>
			<div>
				<div className="d-flex justify-content-between">
					<Typography className="body1 mb-3">
						<strong>
							Invite User by sending an email link to create account{" "}
						</strong>
					</Typography>
					<IconButton
						onClick={() => {
							setNewUserModel(false);
						}}
						style={{ fontSize: "12px" }}
					>
						<Close style={{ fontSize: "1.5rem", marginTop: "-4px" }} />
					</IconButton>
				</div>

				<div className="">
					<label>
						<strong>User Email</strong>
					</label>
					<TextField
						className="form-field mt-2"
						placeholder="Enter Email"
						type="email"
						style={{ width: "90%" }}
					/>
				</div>
				<div>
					<button className={"primaryButton mt-3"} style={{ width: "90%" }}>
						Send Invite
					</button>
				</div>
			</div>
		</>
	);
};

export default UsersList;
