import React from "react";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import microsoftLogo from "assets/loginRegistration/microsoftLogo.png";
// import { activateUserSignIn } from "core/utils/appUtils";
import { Button } from "@mui/material";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

export const MicrosoftLogin = (props) => {
	const { instance } = useMsal();

	const handleLogin = (loginType) => {
		instance
			.loginPopup(loginRequest)
			.then((response) => {
				console.log("Check the loginPopup Response", response);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<>
			<div>
				<Button
					// variant="outlined"
					className="primaryButton hoverEffect social-btn"
					onClick={() => {
						handleLogin();
					}}
				>
					<span className="social-logo">
						<MicrosoftSignIn />
					</span>
					<span className="social-text">Sign in with Microsoft</span>
				</Button>
			</div>
		</>
	);
};

export const MicrosoftSignIn = () => {
	return (
		<>
			<div className="social-logo">
				<img src={microsoftLogo} alt={"microsoftLogo"} />
			</div>
		</>
	);
};

export default MicrosoftLogin;
