import axios from "axios";
import {
  INVOICE_LOADING,
  INVOICE_GET_ALL_SUCCESS,
  INVOICE_ERROR,
  INVOICE_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const invoiceGetAll = () => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: INVOICE_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/invoice`, {})
        .then((response) => {
          dispatch({
            type: INVOICE_GET_ALL_SUCCESS,
            payload: {
              invoiceList: response.data,
              loading: false,
              status: "success",
              text: "Get All INVOICE data successfully.",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: INVOICE_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting INVOICE data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const invoiceDelete = (invoice) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: INVOICE_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/invoice/${invoice.INVOICE_ID}`, {})
        .then((response) => {
          axios
            .get(`${server_url}/invoice`, {})
            .then((response) => {
              dispatch({
                type: INVOICE_GET_ALL_SUCCESS,
                payload: {
                  invoiceList: response.data,
                  loading: false,
                  status: "success",
                  text: "Delete INVOICE data successfully.",
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: INVOICE_ERROR,
                payload: {
                  text: "Error occured during getting INVOICE data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: INVOICE_ERROR,
            payload: {
              text: "Error occured during deleteing INVOICE data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const invoiceAddOrUpdate = (invoice, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: INVOICE_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/invoice`, invoice)
          .then((response) => {
            axios
              .get(`${server_url}/invoice`)
              .then((response) => {
                dispatch({
                  type: INVOICE_GET_ALL_SUCCESS,
                  payload: {
                    invoiceList: response.data,
                    loading: false,
                    status: "success",
                    text: "Add INVOICE data successfully.",
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: INVOICE_ERROR,
                  payload: {
                    text: "Error occured during getting INVOICE data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: INVOICE_ERROR,
              payload: {
                text: "Error occured during adding INVOICE data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/invoice`, invoice)
          .then((response) => {
            axios
              .get(`${server_url}/invoice`)
              .then((response) => {
                dispatch({
                  type: INVOICE_GET_ALL_SUCCESS,
                  payload: {
                    invoiceList: response.data,
                    loading: false,
                    status: "success",
                    text: "Update INVOICE data successfully.",
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: INVOICE_ERROR,
                  payload: {
                    text: "Error occured during getting INVOICE data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: INVOICE_ERROR,
              payload: {
                text: "Error occured during updating INVOICE data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const invoiceClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: INVOICE_CLEAR_SNACKBAR,
    });
  };
};
