import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
	TextField,
	MenuItem,
	Box,
	Chip,
	Typography,
	IconButton,
} from "@mui/material";
import { addJobForm } from "./addJobUtils";
import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";
import GeolocationField from "components/Location/GeoLocation";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import DatePickerField from "components/DateComponets/DatePicker";
import { addNewJob } from "actions/jobActions";
import isUndefined from "lodash/isUndefined";

export const AddNewJob = ({
	onCancel,
	jobId,
	selectedJob,
	title,
	parentId,
}) => {
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);
	const [showLocationModal, setShowLocationModal] = useState(false);

	const { steps, formFields, validationSchema, stepDescription } = addJobForm;
	const initialState = addJobForm.defaultState(get(selectedJob, "data", null));

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			console.log("Check the Onsubmit : ");
		},
	});
	const handleNext = () => {
		const currentFields = formFields[`step${activeStep}`]
			.filter((field) => field.required)
			.map((field) => field.key);
		const currentTouchedFields = pick(formik.touched, currentFields);
		const currentErrorFields = pick(formik.errors, currentFields);
		if (activeStep + 1 < steps.length) {
			if (
				currentFields.length === keys(currentTouchedFields).length &&
				keys(currentErrorFields).length === 0
			) {
				setActiveStep(activeStep + 1);
			}
		}
		if (activeStep + 1 === steps.length) {
			if (keys(formik.errors).length === 0) {
				console.log("IN Dispatch : ", parentId);
				dispatch(
					addNewJob({ ...formik.values, ...parentId }, (payload) => {
						// navigate("/jobs");
						onCancel(false);
					})
				);
			}
		}
	};

	return (
		<div className="mt-2 p-2">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h1" id="header">
					{title || "Create New Job"}
				</Typography>
				<IconButton onClick={() => onCancel(false)}>
					<CloseOutlined />
				</IconButton>
			</div>
			<ModelDialog
				open={showLocationModal}
				fullWidth={true}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography variant="h6">{"Set Property Location"}</Typography>
						<IconButton onClick={() => setShowLocationModal(false)}>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					<div>
						<div>
							<GeolocationField
								handleMarkerClick={(locationLink) => {
									formik.setFieldValue("propertyLocation", locationLink);
								}}
							/>
						</div>
						<div style={{ textAlign: "right" }}>
							<button
								className={`save-details-button m-4 primaryButton`}
								onClick={() => {
									setShowLocationModal(false);
								}}
							>
								{"Save"}
							</button>
						</div>
					</div>
				}
			/>
			<div className="">
				<div>
					<Typography variant="subtitle1" className="text-left m-1">
						{stepDescription[`step${activeStep}`].heading
							? `${activeStep + 1}. ${
									stepDescription[`step${activeStep}`].heading
							  }`
							: ""}
					</Typography>
					<div>
						<p className="m-1">
							<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
							<b>{stepDescription[`step${activeStep}`].subHeading}</b>
						</p>
					</div>
				</div>
				<div>
					<form onSubmit={formik.handleSubmit}>
						<div className="stepContent container row">
							{formFields[`step${activeStep}`].map((field, index) => {
								const extraProps = get(field, "extraInputProps", {});
								return (
									<div
										className={`${field.className} mt-3`}
										style={{ width: field.width }}
									>
										<p>
											<label className="form-label">
												{field.label}
												{field.required && (
													<sup
														style={{ color: "red", fontSize: "1em", top: 0 }}
													>
														*
													</sup>
												)}
											</label>
										</p>
										{field.type === "dateField" ? (
											<DatePickerField
												onChange={(date) => {
													console.log("Check the Date : ", date);
													formik.setFieldValue(field.key, date);
												}}
												value={formik.values[field.key]}
											/>
										) : (
											<TextField
												key={field.key}
												id={field.key}
												type={field.type}
												select={field.select}
												name={field.key}
												fullWidth
												variant="outlined"
												placeholder={field.placeholder}
												className={`registration-formField `}
												value={formik.values[field.key]}
												disabled={field.disabled}
												onChange={(e) => {
													if (field.formatter) {
														const formattedValue = field.formatter(
															e.target.value
														);
														formik.setFieldValue(field.key, formattedValue);
													} else {
														formik.handleChange(e);
													}
												}}
												onBlur={formik.handleBlur}
												error={
													formik.touched[field.key] &&
													Boolean(formik.errors[field.key])
												}
												helperText={
													formik.touched[field.key] && formik.errors[field.key]
												}
												SelectProps={{
													displayEmpty: true,
													multiple: field.multiple,
													renderValue: (selected, x, y, z) => {
														if (field.multiple) {
															if (selected && selected.length === 0) {
																return <em>{field.placeholder}</em>;
															}
															return (
																<Box
																	sx={{
																		display: "flex",
																		flexWrap: "wrap",
																		gap: 0.5,
																		marginTop: "-0.75rem",
																	}}
																>
																	{selected &&
																		selected.length > 0 &&
																		selected.map((value) => (
																			<Chip
																				key={value}
																				label={value}
																				style={{ fontSize: "12px" }}
																				variant="outlined"
																				onDelete={() => {
																					formik.setFieldValue(
																						field.key,
																						selected.filter(
																							(item) => item !== value
																						)
																					);
																				}}
																			/>
																		))}
																</Box>
															);
														} else {
															return (
																<Typography>
																	{typeof field.options[0] === "string"
																		? selected
																		: field.options.find(
																				(option) => option.key === selected
																		  )?.text}
																</Typography>
															);
														}
													},
												}}
												{...extraProps}
											>
												<MenuItem value={""} disabled>
													{field.placeholder}
												</MenuItem>

												{field.options &&
													field.options.map((option) => {
														return !isUndefined(option.key) ? (
															<MenuItem
																key={option.key}
																value={option.key}
																selected={
																	formik.values[field.key] === option.key
																}
															>
																{option.text}
															</MenuItem>
														) : (
															<MenuItem value={option}>{option}</MenuItem>
														);
													})}
											</TextField>
										)}
									</div>
								);
							})}
							<div style={{ textAlign: "right" }}>
								<div className="d-flex justify-content-between">
									<div>
										{activeStep !== 0 && (
											<button
												type="submit"
												className={`save-details-button m-4 primaryButton`}
												onClick={() => {
													setActiveStep(activeStep - 1);
												}}
											>
												{"< Previous"}
											</button>
										)}
									</div>
									<div className="float-right">
										<button
											type="submit"
											className={`save-details-button m-4 primaryButton`}
											onClick={() => {
												handleNext();
											}}
										>
											{activeStep + 1 === steps.length ? "Save" : "Next >"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewJob;
