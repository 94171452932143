import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import queryString from "query-string";

export const fetchUsers = () => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_USERS,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/user`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_USERS_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_USERS_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const inviteNewUser = (emailId, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.INVITE_NEW_USER,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/user`,
				body: {
					email: emailId,
				},
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.INVITE_NEW_USER_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					onSuccess();
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.INVITE_NEW_USER_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const addNewUser = (formData, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.ADD_NEW_USER,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/user`,
				body: {
					...formData,
				},
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.ADD_NEW_USER_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					onSuccess();
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.ADD_NEW_USER_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const saveWeeklyTimesheet = (weekLogData, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.SAVE_TIME_SHEET,
				payload: {
					loading: true,
				},
			});
			dispatch({ type: ActionConstants.LOADING_APP });
			request({
				method: "POST",
				url: `${server_url}/timeLog`,
				body: weekLogData,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.SAVE_TIME_SHEET_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					dispatch({ type: ActionConstants.LOADING_APP_SUCCESS });
					onSuccess();
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.SAVE_TIME_SHEET_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({ type: ActionConstants.LOADING_APP_ERROR });
					resolve();
				});
		});
	};
};

export const fetchTimesheetData = (params) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_TIME_SHEET,
				payload: {
					loading: true,
				},
			});
			dispatch({ type: ActionConstants.LOADING_APP });
			const queryParams = queryString.stringify(params);
			request({
				method: "GET",
				url: `${server_url}/timeLog?${queryParams}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_TIME_SHEET_SUCCESS,
						payload: {
							response: response.data.items,
							totalCount: response.data.totalCount,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					fetchTimesheetData({ page: 0, pageSize: 10 });
					dispatch({ type: ActionConstants.LOADING_APP_SUCCESS });
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_TIME_SHEET_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({ type: ActionConstants.LOADING_APP_ERROR });
					resolve();
				});
		});
	};
};
