import React from "react";
import { Grid, AppBar, Tab, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextFields from "../../components/TextFields/TextFields_old";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";

import DynamicDropdown from "../../components/DynamicDropdown/DynamicDropdown";

const LabTabs = (props) => {
	const classes = useStyles();
	const [value, setValue] = React.useState("1");
	const [state, setState] = React.useState({
		status: false,
	});

	// const [valuee, setValuee] = React.useState('1');
	const [inputValue, setInputValue] = React.useState("");
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeINVOICE_STATUS_ID = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
		var newValue = event.target.value;
		props.setNewINVOICE("INVOICE_STATUS_ID", newValue);
	};
	const handleChangeJOB_ID = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
		var newValue = event.target.value;
		props.setNewINVOICE("JOB_ID", newValue);
	};

	return (
		<div className={classes.root}>
			<TabContext value={value}>
				<AppBar position="static">
					<TabList onChange={handleChange} aria-label="simple tabs example">
						<Tab label="Tab 1" value="1" />
						<Tab label="Tab 2" value="2" />
					</TabList>
				</AppBar>
				<TabPanel value="1">
					<Grid container spacing={1}>
						<Grid item xs={4}>
							<TextFields
								value={props.editINVOICE.INVOICE_NUMBER}
								SetNewValue={props.setNewINVOICE}
								name="INVOICE_NUMBER"
								label="INVOICE_NUMBER"
								type="string"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextFields
								value={props.editINVOICE.INVOICE_DATE}
								SetNewValue={props.setNewINVOICE}
								name="INVOICE_DATE"
								label="INVOICE_DATE"
								type="date"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextFields
								value={props.editINVOICE.AMOUNT}
								SetNewValue={props.setNewINVOICE}
								name="AMOUNT"
								label="AMOUNT"
								type="string"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextFields
								value={props.editINVOICE.PAID_AMOUNT}
								SetNewValue={props.setNewINVOICE}
								name="PAID_AMOUNT"
								label="PAID_AMOUNT"
								type="string"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextFields
								value={props.editINVOICE.PAID_DATE}
								SetNewValue={props.setNewINVOICE}
								name="PAID_DATE"
								label="PAID_DATE"
								type="date"
							/>
						</Grid>
						<Grid item xs={4}>
							<FormControl
								lassName={classes.formControl}
								style={{ width: "150px", marginTop: "15px" }}
							>
								<DynamicDropdown
									value={props.editINVOICE.INVOICE_STATUS_ID}
									options={[]}
									onChange={handleChangeINVOICE_STATUS_ID}
									dataType={"INVOICE_STATUS"}
									nameColumn={"STATUS_NAME"}
									idColumn={"INVOICE_STATUS_ID"}
									controlID={"INVOICE_STATUS_ID"}
									label={"STATUS"}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value="2">
					<Grid container spacing={1}>
						<Grid item xs={4}>
							<FormControl
								lassName={classes.formControl}
								style={{ width: "150px", marginTop: "15px" }}
							>
								<DynamicDropdown
									value={props.editINVOICE.JOB_ID}
									options={[]}
									onChange={handleChangeJOB_ID}
									dataType={"JOB"}
									nameColumn={"NAME"}
									idColumn={"JOB_ID"}
									controlID={"JOB_ID"}
									label={"JOB"}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</TabPanel>
			</TabContext>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
	},
}));

export default LabTabs;
