import {
  CUSTOMER_TYPE_LOADING,
  CUSTOMER_TYPE_GET_ALL_SUCCESS,
  CUSTOMER_TYPE_ERROR,
  CUSTOMER_TYPE_CLEAR_SNACKBAR,
} from "../constants/types";

const initialState = {
  customer_typeList: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const customer_typeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_TYPE_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case CUSTOMER_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMER_TYPE_ERROR:
      return {
        ...state,
        customer_typeList: [],
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case CUSTOMER_TYPE_GET_ALL_SUCCESS:
      return {
        ...state,
        customer_typeList: action.payload.customer_typeList,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    default:
      return state || {};
  }
};

export default customer_typeReducer;
