import React, { useEffect, useState } from "react";
import AddEditTask from "./AddNewTask";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { fetchTasks } from "actions/taskActions";
import { taskDelete, taskGetAll } from "actions/taskActions";
import { getTasks } from "selectors/taskSelectors";
import BreadCrumb from "components/BreadCrumb";
import DataGridTable from "components/Table/DataGrid";

import get from "lodash/get";
import { useNavigate } from "react-router-dom";
import { taskListColumns } from "./addTaskUtils";
import ProjectContext from "context/ProjectContext";
import NoResultsFound from "components/NoResults/NoResultsFound";

export const Tasks = () => {
	const { selectedProjectContext } = React.useContext(ProjectContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [addNewTask, setAddNewTask] = useState(false);
	const { tasksList, totalCount } = useSelector(getTasks);

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [editTask, setEditTask] = useState(false);

	const [selectedTask, setSelectedTask] = useState(null);

	useEffect(() => {
		dispatch(
			taskGetAll({ ...pageModel, projectId: selectedProjectContext?.projectId })
		);
	}, [dispatch, selectedProjectContext, pageModel]);

	return (
		<div className="tasks-page">
			{addNewTask ? (
				<AddEditTask
					onCancel={() => {
						setAddNewTask(false);
						setEditTask(null);
						setSelectedTask(null);
					}}
					jobId={editTask}
					selectedTask={selectedTask}
					title={get(selectedTask, "data.taskName", null)}
				/>
			) : (
				<>
					<div className="tasks-header-section">
						<div>
							<Typography className="h1"> Tasks</Typography>
							<BreadCrumb />
						</div>
					</div>
					<div className="tasks-content-section">
						{totalCount > 0 ? (
							<DataGridTable
								onRowClick={(row, params) => {
									navigate(`/tasks/view/${row.id}`);
								}}
								pagination={totalCount > 10}
								totalCount={totalCount}
								columns={taskListColumns({
									setEditTask,
									setAddNewTask,
									setSelectedTask,
									onTaskDelete: (taskId) => {
										dispatch(taskDelete(taskId, pageModel));
									},
								})}
								data={tasksList}
								defineRowId={(row) => {
									// return row.taskId;
									return row.taskId;
								}}
								paginationModel={pageModel}
								onPaginationChange={(props) => {
									console.log("Pagination Props : ", props);
									setPageModel(props);
								}}
								onSelect={(props) => {
									console.log("Selected Rows : ", props);
									setSelectedRows(props);
								}}
								selectedRows={selectedRows}
								onDeleteSelection={() => {
									console.log("Delete Selection : ", selectedRows);
								}}
							/>
						) : (
							<NoResultsFound />
						)}
					</div>
				</>
			)}

			{!addNewTask && (
				<div className="page-footer  fixed-footer align-content-center">
					<div className="d-flex justify-content-end ">
						<button
							className="primaryButton addTaskButton"
							style={{ width: "8rem" }}
							onClick={() => {
								setAddNewTask(!addNewTask);
							}}
						>
							{"+ Add Task"}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Tasks;
