import * as yup from "yup";
import { statesList } from "core/utils/statesList";
import { getText } from "core/utils/getContentText";

const getTextContent = (key) => getText(`projects.addProperty.${key}`);

const addPropertyValidationSchema = yup.object({
	propertyName: yup
		.string("Enter Property Name")
		.required("Property Name is required"),
	address: yup.string("Enter address").required("address is required"),
	city: yup.string("Enter city").required("city is required"),
	state: yup.string("Enter state").required("state is required"),
	zip: yup.string("Enter zipcode").required("ZipCode is required"),
	propertyDetails: yup
		.string("Enter Property Details")
		.required("Property Details is required"),
	propertyLocation: yup
		.string("Enter Property Location")
		.required("Property Location is required"),
	legalDescription: yup
		.string("Enter Legal Description")
		.required("Legal Description is required"),
	bedroom: yup
		.number("Enter no.of bedrooms")
		.required("Bedroom count is required")
		.typeError("Please enter a valid number")
		.integer("Bedroom count must be an integer"),
	bath: yup
		.number("Enter no.of bathrooms")
		.required("bathrooms count is required")
		.typeError("Please enter a valid number")
		.integer("Bathroom count must be an integer"),

	garages: yup
		.number("Enter no.of garages")
		.required("garages count is required")
		.typeError("Please enter a valid number")
		.integer("Garage count must be an integer"),

	stories: yup
		.number("Enter no.of stories")
		.required("stories count is required")
		.typeError("Please enter a valid number")
		.integer("Stories count must be an integer"),
	buildingSize: yup
		.number("Enter Sq.Ft of Building")
		.required("Sq.Ft of Building is required"),
	lotSize: yup.number("Enter lotSize").required("lotSize count is required"),
});
export const addPropertyForm = {
	steps: Array.from({ length: 2 }, (_, index) =>
		getText(`profile.contractor.step${index + 1}`)
	),
	stepDescription: {
		step0: {
			heading: "Property Location Details",
		},
		step1: {
			heading: "Property Details",
			subHeading:
				"Please enter the number of bedrooms, bathrooms, garage spaces, and stories. Additionally, provide the building size and lot size, along with the total construction area in square feet.",
		},
		step3: {
			heading: "Timeline",
		},
		step4: {
			heading: "Payment Schedule",
		},
	},
	formFields: {
		step0: [
			{
				key: "propertyName",
				placeholder: "Property Name",
				label: getTextContent("propertyName"),
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "address",
				placeholder: "Property Address",
				label: getTextContent("propertyAddress"),
				type: "textArea",
				required: true,
				width: "100%",
			},
			{
				key: "city",
				placeholder: "City",
				label: getTextContent("city"),
				type: "text",
				required: true,
				width: "33%",
			},
			{
				key: "state",
				placeholder: "state",
				label: getTextContent("state"),
				type: "select",
				select: true,
				options: statesList,
				required: true,
				width: "33%",
			},
			{
				key: "zip",
				placeholder: "Zip",
				label: getTextContent("zip"),
				type: "text",
				required: true,
				width: "33%",
			},
			{
				key: "propertyDetails",
				placeholder: "Details of Property",
				label: getTextContent("propertyDetails"),
				type: "textArea",
				required: true,
				width: "50%",
				extraInputProps: {
					multiline: true,
				},
			},
			{
				key: "propertyLocation",
				placeholder: "Design of Property",
				label: getTextContent("propertyLocation"),
				type: "text",
				required: true,
				width: "50%",
				// className: "col-4 col-xs-12",
			},
		],
		step1: [
			{
				key: "legalDescription",
				placeholder: "Legal Description to know more about Property",
				label: getTextContent("legalDescription"),
				required: true,
				type: "textarea",
				width: "100%",
				extraInputProps: {
					multiline: true,
				},
			},
			{
				key: "propertyType",
				placeholder: "Property Type",
				label: getTextContent("propertyType"),
				type: "text",
				width: "50%",
			},

			{
				key: "architectureStyle",
				placeholder: "Architechture Style",
				label: getTextContent("architectureStyle"),
				type: "text",
				width: "50%",
			},

			{
				key: "bedroom",
				placeholder: "Bedroom",
				label: getTextContent("bedroom"),
				type: "text",
				required: true,
				width: "50%",
			},

			{
				key: "bath",
				placeholder: "Bathroom",
				label: getTextContent("bathroom"),
				type: "text",
				required: true,
				width: "50%",
			},

			{
				key: "garages",
				placeholder: "Garage",
				label: getTextContent("garage"),
				type: "text",
				required: true,
				width: "50%",
			},

			{
				key: "stories",
				placeholder: "Stories",
				label: getTextContent("stories"),
				type: "text",
				required: true,
				width: "50%",
			},

			{
				key: "buildingSize",
				placeholder: "Building Size",
				label: getTextContent("buildingSize"),
				type: "text",
				required: true,
				width: "50%",
			},
			{
				key: "lotSize",
				placeholder: "Lot Size",
				label: getTextContent("lotSize"),
				type: "text",
				required: true,
				width: "50%",
			},
		],
	},
	validationSchema: addPropertyValidationSchema,
	defaultState: (defaultState) =>
		defaultState ||
		Object.fromEntries(
			Object.keys(addPropertyValidationSchema.fields).map((key) =>
				key === "serviceAreas" ? [key, []] : [key, ""]
			)
		),
};
