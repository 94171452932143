import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { fetchTaskById } from "actions/taskActions";
import { convertCamelToNormal } from "core/utils/appUtils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTaskData } from "selectors/taskSelectors";

export const ViewJob = ({ task }) => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const taskData = useSelector(getTaskData);

	useEffect(() => {
		dispatch(fetchTaskById(params.taskId));
	}, [dispatch, params.taskId]);

	return (
		<>
			<div className="d-flex justify-content-between">
				<Typography className="h1 mt-3">
					{taskData.header || "View Task"}
				</Typography>
				<IconButton>
					<Close onClick={() => navigate("/projects")} />
				</IconButton>
			</div>
			<div className="m-3 mt-5 mb-5">
				<Typography className="h2">Task Details:</Typography>
				<Typography className="body1">{taskData.description}</Typography>
			</div>
			<div className="d-flex flex-wrap">
				{Object.keys(taskData).map((key) => {
					return (
						<div className="m-3 col-3">
							<Typography className="body1">
								<strong>{convertCamelToNormal(key)}</strong>
							</Typography>
							<Typography style={{ wordBreak: "all" }} className="body1">
								{key === "siteLocation" ? (
									<a
										href={taskData[key]}
										target="_blank"
										rel="noreferrer"
										className="link-text"
									>
										View Location
									</a>
								) : (
									taskData[key]
								)}
							</Typography>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default ViewJob;
