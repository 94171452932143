import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

export const DatePickerField = ({
	value = moment().format("YYYY-MM-DD"),
	onChange,
	field,
}) => {
	// const [value, setValue] = React.useState(
	// 	dayjs(moment().format("YYYY-MM-DD"))
	// );
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				value={dayjs(value)}
				className="registration-formField"
				openTo="month"
				views={["year", "month", "day"]}
				onChange={(newValue) => {
					onChange(moment(new Date(newValue)).format("YYYY-MM-DD"));
				}}
			/>
		</LocalizationProvider>
	);
};

export default DatePickerField;
