import React, { useState, useEffect, useCallback } from "react";
import {
	format,
	startOfWeek,
	endOfWeek,
	eachDayOfInterval,
	subWeeks,
	addWeeks,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "selectors/projectSelector";
import { jobGetAll } from "actions/jobActions";
import {
	Grid,
	IconButton,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography,
	TableHead,
	Popover,
} from "@mui/material";

import { fetchProjects } from "actions/projectActions";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { getJobsList } from "selectors/jobSelectors";
import { calculateEndDate, calculateHours } from "./timesheetUtils";
import { saveWeeklyTimesheet } from "actions/userActions";
import ProjectContext from "context/ProjectContext";

export const WeekTimeSheet = () => {
	const dispatch = useDispatch();
	const { selectedProjectContext } = React.useContext(ProjectContext);
	const [selectedWeek, setSelectedWeek] = useState(
		startOfWeek(new Date(), { weekStartsOn: 1 })
	);
	const { projectsList } = useSelector(getProjects);
	const { jobsList } = useSelector(getJobsList);
	const [selectedProject, setSelectedProject] = useState({});
	const [selectedJob, setSelectedJob] = useState({});
	const [timeEntries, setTimeEntries] = useState([]);
	const [currentRow, setCurrentRow] = useState({});

	const [activePopup, setActivePopup] = useState(null);
	const [popupId, setPopupId] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		dispatch(fetchProjects());
	}, [dispatch]);

	const getDateKey = (date) => format(date, "MMM/d");
	const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });

	const initiateTimeEntries = useCallback(() => {
		const weekStart = startOfWeek(selectedWeek, { weekStartsOn: 1 });
		const weekDays = eachDayOfInterval({
			start: weekStart,
			end: endOfWeek(weekStart, { weekStartsOn: 1 }),
		});

		const weekDaysList = weekDays.reduce(
			(acc, day) => ({
				...acc,
				[getDateKey(day)]: {
					date: day,
					hours: "",
					startTime: new Date(day),
					endTime: new Date(day),
				},
			}),
			{}
		);

		setTimeEntries((prevEntries) => ({
			...weekDaysList,
			// ...prevEntries,
		}));
	}, [selectedWeek]);

	useEffect(() => {
		initiateTimeEntries();
	}, [initiateTimeEntries]);

	const handleWeekChange = (direction) => {
		setSelectedWeek((prevWeek) =>
			direction === "prev"
				? subWeeks(prevWeek, 1)
				: direction === "next"
				? addWeeks(prevWeek, 1)
				: currentWeekStart
		);
	};
	const handleChange = (date, changeEvent) => {
		setTimeEntries((prevState) => {
			return {
				...prevState,
				[getDateKey(date)]: {
					projectId: selectedProject.projectId,
					projectName: selectedProject.projectName,
					jobId: selectedJob.jobId,
					jobName: selectedJob.jobName,
					...prevState[getDateKey(date)],
					...changeEvent,
				},
			};
		});
	};

	const renderPopup = (selectedDate) => {
		const { startTime, endTime } = timeEntries[getDateKey(selectedDate)];
		// Function to format the date to 'YYYY-MM-DDTHH:MM'
		const formatDateTimeLocal = (date) => {
			const d = new Date(date);
			const pad = (num) => (num < 10 ? "0" + num : num);
			return (
				d.getFullYear() +
				"-" +
				pad(d.getMonth() + 1) +
				"-" +
				pad(d.getDate()) +
				"T" +
				pad(d.getHours()) +
				":" +
				pad(d.getMinutes())
			);
		};

		// if (activePopup) {
		return (
			<div className="popup">
				<div className="mt-2">
					<label>Start Time</label>
					<TextField
						type="datetime-local"
						className="form-field hours-date-input mt-2"
						value={formatDateTimeLocal(startTime)}
						onChange={(e) => {
							// setCurrentRow({ ...currentRow, startDate: e.target.value });
							handleChange(selectedDate, { startTime: e.target.value });
							// setTimeEntries((prevState) => {
							// 	return {
							// 		...prevState,
							// 		[getDateKey(selectedDate)]: {
							// 			...prevState[getDateKey(selectedDate)],
							// 			startTime: e.target.value,
							// 		},
							// 	};
							// });
						}}
					/>
				</div>
				<div className="mt-2">
					<label>End Time</label>
					<TextField
						type="datetime-local"
						className="form-field hours-date-input mt-2"
						value={formatDateTimeLocal(endTime)}
						onChange={(e) => {
							const hours = calculateHours(
								timeEntries[getDateKey(selectedDate)].startTime,
								e.target.value
							);
							handleChange(selectedDate, { endTime: e.target.value, hours });
						}}
					/>
				</div>
				<button
					className="primaryButton mt-3"
					onClick={() => {
						const hours = calculateHours(
							currentRow.startDate,
							currentRow.endDate
						);
						setCurrentRow({
							...currentRow,
							hours,
						});
						setActivePopup(false);
					}}
				>
					Close
				</button>
			</div>
		);
		// }
	};
	const getFormattedWeekRange = (weekStart) => {
		const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
		return `${format(weekStart, "MM/dd")} - ${format(weekEnd, "MM/dd")}`;
	};

	const saveTimesheet = () => {
		console.log("Data to send to backend:", timeEntries);
		const filteredData = Object.values(timeEntries).filter((entry) => {
			return entry.jobId;
		});
		// setTimesheetData((prevState) => [...prevState, ...filteredData]);
		dispatch(saveWeeklyTimesheet(filteredData));
		setSelectedJob({});
		setSelectedProject({});
		initiateTimeEntries();

		// Here you would send dataToSend to your backend
	};

	return (
		<>
			<div>
				<div>
					<Typography className="timesheet-title">
						<span style={{ fontSize: "20px" }}>
							<strong>Log Hours for</strong>
						</span>
						<span className="h1">"{selectedProjectContext?.projectName}"</span>
					</Typography>
				</div>
				<Grid container justifyContent={"end"}>
					<IconButton
						onClick={() => handleWeekChange("prev")}
						disabled={selectedWeek <= subWeeks(currentWeekStart, 1)}
						style={{ fontSize: "12px" }}
					>
						<ArrowBackOutlined />
						Prev Week
					</IconButton>
					<div className="align-content-center">
						<Typography className="subtitle1">
							Selected Week: {getFormattedWeekRange(selectedWeek)}
						</Typography>
					</div>
					<IconButton
						onClick={() => handleWeekChange("next")}
						disabled={selectedWeek >= currentWeekStart}
						style={{ fontSize: "12px" }}
					>
						<ArrowForwardOutlined /> Next Week
					</IconButton>
				</Grid>
				<Grid style={{ width: "100%", overflowX: "auto" }}>
					<Table className="basic-table">
						<TableHead>
							<TableRow>
								<TableCell style={{ minWidth: "10rem" }}>Project</TableCell>
								<TableCell style={{ minWidth: "10rem" }}>Job</TableCell>
								{Object.values(timeEntries).map((entry, index) => {
									return <TableCell>{format(entry.date, "MMM dd")}</TableCell>;
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									<TextField
										type="select"
										style={{ minWidth: "11rem" }}
										className="form-field"
										select
										value={selectedProject.projectId || ""}
										onChange={(e) => {
											dispatch(jobGetAll({ projectId: e.target.value }));
										}}
									>
										{projectsList.map((project) => {
											return (
												<MenuItem
													value={project.projectId}
													onClick={() => {
														setSelectedProject(project);
													}}
												>
													<Typography>{project.projectName}</Typography>
												</MenuItem>
											);
										})}
									</TextField>
								</TableCell>
								<TableCell>
									<TextField
										disabled={selectedProject.projectId ? false : true}
										type="select"
										style={{
											minWidth: "11rem",
										}}
										className={`form-field ${
											!selectedProject.projectId ? "disabled" : ""
										}`}
										select
										value={selectedJob.jobId || ""}
									>
										{jobsList.map((job) => {
											return (
												<MenuItem
													value={job.jobId}
													onClick={() => {
														setSelectedJob(job);
													}}
												>
													<Typography>{job.jobName}</Typography>
												</MenuItem>
											);
										})}
									</TextField>
								</TableCell>
								{Object.values(timeEntries).map((entry, index) => {
									return (
										<TableCell key={index} className="hours-cell">
											<div className="d-flex">
												<TextField
													disabled={selectedJob.jobId ? false : true}
													type="number"
													variant="outlined"
													value={entry.hours}
													onChange={(e) => {
														e.preventDefault();
														const hours = parseFloat(e.target.value);
														const endTime = calculateEndDate(
															timeEntries[getDateKey(entry.date)].startTime,
															hours
														);
														handleChange(entry.date, { hours, endTime });
													}}
													className={`form-field ${
														!selectedJob.jobId ? "disabled" : ""
													}`}
												/>
												<button
													aria-describedby={popupId}
													disabled={selectedJob.jobId ? false : true}
													className="popup-trigger"
													onClick={(event) => {
														setActivePopup(!activePopup);
														setPopupId(getDateKey(entry.date));
														setAnchorEl(event.currentTarget);
													}}
												>
													⋮
												</button>
												{getDateKey(entry.date) === popupId && activePopup && (
													<Popover
														id={
															Boolean(anchorEl) ? "simple-popover" : undefined
														}
														open={
															// getDateKey(entry.date) === popupId &&
															//
															Boolean(anchorEl)
														}
														anchorEl={anchorEl}
														onClose={() => {
															setAnchorEl(null);
															setActivePopup(false);
														}}
														anchorOrigin={{
															vertical: "bottom",
															horizontal: "left",
														}}
														transformOrigin={{
															vertical: "top",
															horizontal: "left",
														}}
													>
														{renderPopup(entry.date)}
													</Popover>
												)}
											</div>
										</TableCell>
									);
								})}
							</TableRow>
						</TableBody>
					</Table>
				</Grid>
				<div className="timesheet-actions mt-3">
					<button
						onClick={saveTimesheet}
						disabled={!selectedProject.projectId || !selectedJob.jobId}
						className={`timesheet-button save-button ${
							!selectedProject.projectId || !selectedJob.jobId ? "disabled" : ""
						}`}
					>
						Save Timesheet
					</button>
				</div>
			</div>
		</>
	);
};

export default WeekTimeSheet;
