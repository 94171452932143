import {
  CUSTOMER_LOADING,
  CUSTOMER_GET_ALL_SUCCESS,
  CUSTOMER_ERROR,
  CUSTOMER_CLEAR_SNACKBAR,
} from "../constants/types";

const initialState = {
  customerList: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case CUSTOMER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMER_ERROR:
      return {
        ...state,
        customerList: [],
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case CUSTOMER_GET_ALL_SUCCESS:
      return {
        ...state,
        customerList: action.payload.customerList,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    default:
      return state || {};
  }
};

export default customerReducer;
