import React from "react";
import { useSelector } from "react-redux";
import { getAppRole } from "selectors/appSelector";

const AppRoleContext = React.createContext();

export const AppRoleProvider = ({ children }) => {
	const appRole = useSelector(getAppRole);

	return (
		<AppRoleContext.Provider value={appRole}>
			{children}
		</AppRoleContext.Provider>
	);
};

export default AppRoleContext;
