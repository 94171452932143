import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.app;

export const appLoadingSelector = createSelector(selectState, (app) =>
	get(app, "loading", false)
);
export const loginErrorSelector = createSelector(selectState, (app) =>
	get(app, "loginError", null)
);

export const getUserProfileDetails = createSelector(selectState, (app) =>
	get(app, "userDetails", {})
);

export const getDashboardDetails = createSelector(selectState, (app) =>
	get(app, "dashboardData", {})
);

export const getAppRole = createSelector(getUserProfileDetails, (user) => {
	return {
		roleCategory: get(user, "role.applUserCategory", "default"),
		roleName: get(user, "role.applRoleName", "default"),
	};
});
