import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.task;

export const getTasks = createSelector(selectState, (task) => ({
	tasksList: get(task, "taskList", []),
	totalCount: get(task, "totalCount", 0),
}));

export const getTaskData = createSelector(selectState, (task) =>
	get(task, "taskData", [])
);
