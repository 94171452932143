import defaultTheme from "./default";

import { createTheme } from "@mui/material";

export const palette = {
	bluePrimary: "#345DAE",
	darkBluePrimary: "#0B2240",
	blackPrimary: "#202842",
	yellowPrimary: "#FAAF40",
	blueSecondary: "#3457DC",
	skyBlueSecondary: "#869FFD",
	lightGraySecondary: "#DDDDDD",
	graySecondary: "#DCDFF1",
	white: "#ffffff",
};

const overrides = {
	typography: {
		htmlFontSize: 10,
		lineHeight: "1rem",
		fontFamily: [
			"Barlow",
			// "sans-serif", // generic fallback font
		].join(","),
		h1: {
			fontSize: "1.5rem",
			color: "black",
			fontWeight: "bolder",
		},
		h2: {
			fontSize: "1.25rem",
			fontWeight: "bold",
		},
		// h3: {
		// 	fontSize: "1.64rem",
		// },
		// h4: {
		// 	fontSize: "1.5rem",
		// },
		// h5: {
		// 	fontSize: "1.285rem",
		// },
		// h6: {
		// 	fontSize: "1.142rem",
		// },
		body1: {
			fontSize: "1rem",
		},
		body2: {
			fontSize: "0.75rem",
		},
	},
};

const overrideTheme = {
	default: createTheme({ ...defaultTheme, ...overrides }),
};

export default overrideTheme;
