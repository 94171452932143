import {
	PROPERTY_LOADING,
	PROPERTY_GET_ALL_SUCCESS,
	PROPERTY_ERROR,
	PROPERTY_CLEAR_SNACKBAR,
} from "../constants/types";
import { ActionConstants } from "constants";

const initialState = {
	propertyList: [],
	totalCount: 0,
	loading: true,
	status: "success",
	text: "",
	showSnackbar: false,
};

const propertyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.ADD_NEW_PROPERTY_SUCCESS:
			return {
				...state,
			};
		case ActionConstants.ADD_NEW_PROPERTY_ERROR:
			return {
				...state,
			};
		case PROPERTY_CLEAR_SNACKBAR:
			return {
				...state,
				showSnackbar: false,
			};
		case PROPERTY_LOADING:
			return {
				...state,
				loading: true,
			};
		case PROPERTY_ERROR:
			return {
				...state,
				propertyList: [],
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case PROPERTY_GET_ALL_SUCCESS:
			return {
				...state,
				propertyList: action.payload.propertyList,
				totalCount: action.payload.totalCount,
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		default:
			return state || {};
	}
};

export default propertyReducer;
