import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { InputAdornment } from "@mui/material";
import { Delete, EditOutlined } from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import moment from "moment";
import { MenuItem } from "@mui/material";

const getTextContent = (key) => getText(`tasks.addTask.${key}`);

const addTaskValidationSchema = yup.object({
	header: yup.string("Enter Task Name").required("Task Name is required"),
	taskDetails: yup
		.string("Enter taskDetails")
		.required("taskDetails are required"),
	stage: yup.string("Select Stage").required("Stage is required"),
	priority: yup.string("Select Priority").required("Priority is required"),
	plannedBudget: yup
		.number("Enter Estimated cost")
		.required("Estimated cost is required")
		.typeError("Please enter a valid number")
		.integer("budget must be an integer"),
	actualCost: yup
		.number("Enter Actual Cost")
		.required("Actual cost is required")
		.typeError("Please enter a valid number")
		.integer("budget must be an integer"),
	plannedStartDate: yup
		.string("Enter Plan Start Date")
		.required("Start Date is required"),
	actualStartDate: yup
		.string("Enter Actual Date")
		.required("Completion Date is required"),
	plannedEndDate: yup
		.string("Enter Plan End Date")
		.required("Start Date is required"),
	actualEndDate: yup
		.string("Enter Actual End Date")
		.required("Completion Date is required"),
});

export const addTaskFields = {
	taskInformation: [
		{
			key: "header",
			label: getTextContent("taskName"),
			placeholder: "Task Name",
			type: "text",
			required: true,
			width: "100%",
		},
		{
			key: "taskDetails",
			label: getTextContent("taskDetails"),
			placeholder: "Enter Task Details here",
			type: "text",
			required: true,
			extraInputProps: {
				multiline: true,
			},
			width: "100%",
			className: "task-details",
		},
		{
			key: "stage",
			label: getTextContent("stage"),
			placeholder: "Select Stage",
			type: "select",
			select: true,
			options: ["Planning", "Pending", "In Progress", "Completed"],
			required: true,
			width: "48%",
		},
		{
			key: "priority",
			placeholder: "Priority of Job",
			label: getTextContent("priority"),
			type: "select",
			select: true,
			required: true,
			options: [
				{ key: 0, text: "Low" },
				{ key: 1, text: "Medium" },
				{ key: 2, text: "High" },
			],
			width: "48%",
		},
		{
			key: "plannedBudget",
			label: getTextContent("plannedBudget"),
			placeholder: "Planned Budget",
			type: "text",
			required: true,
			width: "48%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "actualCost",
			label: getTextContent("actualCost"),
			placeholder: "Enter Actual Cost",
			type: "text",
			required: true,
			width: "48%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "plannedStartDate",
			placeholder: "MM/DD/YYYY",
			label: getTextContent("plannedStartDate"),
			type: "dateField",
			required: true,
			width: "48%",
		},
		{
			key: "actualStartDate",
			placeholder: "MM/DD/YYYY",
			label: getTextContent("actualStartDate"),
			type: "dateField",
			required: true,
			width: "48%",
		},
		{
			key: "plannedEndDate",
			placeholder: "MM/DD/YYYY",
			label: getTextContent("plannedEndDate"),
			type: "dateField",
			required: true,
			width: "48%",
		},
		{
			key: "actualEndDate",
			placeholder: "MM/DD/YYYY",
			label: getTextContent("actualEndDate"),
			type: "dateField",
			required: true,
			width: "48%",
		},
	],
};

export const addTaskForm = {
	validationSchema: addTaskValidationSchema,
	defaultState: (defaultState) => {
		console.log("Check default State : ", defaultState);
		if (defaultState) {
			return defaultState;
		}
		return Object.fromEntries(
			Object.keys(addTaskValidationSchema.fields).map((key) => [key, ""])
		);
	},
};

const priority = {
	0: "Low Priority",
	1: "Medium Priority",
	2: "High Priority",
};
export const taskListColumns = (props) => [
	{
		field: "taskId",
		headerName: "S.no",
		sortable: false,
		width: 50,
		resizable: false,
	},
	{
		field: "header",
		headerName: "Task Name",
		sortable: false,
		width: 150,
		resizable: false,
	},
	{
		field: "details",
		headerName: "Details",
		resizable: false,
		width: 150,
	},
	{
		field: "priority",
		headerName: "Priority",
		resizable: false,
		width: 100,
		valueFormatter: (value) => priority[value],
	},
	{
		field: "plannedBudget",
		headerName: "Budget",
		sortable: false,
		resizable: false,
		width: 100,
		valueFormatter: (value) => `$ ${value}`,
	},
	{
		field: "actualCost",
		headerName: "Actual Cost",
		resizable: false,
		width: 100,
		valueFormatter: (value) => `$ ${value}`,
	},
	{
		field: "plannedStartDate",
		headerName: "Planned Date",
		resizable: false,
		width: 100,
		valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	},
	// {
	// 	field: "actualStartDate",
	// 	headerName: "Start Date",
	// 	// resizable: false,
	// 	width: 100,
	// 	valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	// },
	{
		field: "plannedEndDate",
		headerName: "End Date",
		// resizable: false,
		width: 100,
		valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	},
	// {
	// 	field: "actualEndDate",
	// 	headerName: "Actual End Date",
	// 	// resizable: false,
	// 	width: 100,
	// 	valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	// },

	{
		field: "actions",
		type: "actions",
		width: 100,
		align: "left",
		getActions: (params) => [
			<BurgerMenu>
				<MenuItem
					onClick={() => {
						console.log("Check the Params : ", params);
						props.setEditTask(true);
						props.setAddNewTask(true);
						props.setSelectedTask({ id: params.id, data: params.row });
					}}
				>
					<EditOutlined /> <p className="m-0">Edit</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.onTaskDelete(params.id);
					}}
				>
					<Delete /> Delete Task
				</MenuItem>
			</BurgerMenu>,
		],
	},
];
