import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

const defaultCirceSize = 50;

export const Loader = ({ size = defaultCirceSize }) => {
	return (
		<CircularProgress
			size={size}
			sx={{
				color: "#3457DC",
			}}
		/>
	);
};

export const BackdropLoader = ({ open, size = defaultCirceSize, children }) => {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={open}
		>
			<CircularProgress
				color="inherit"
				size={size}
				sx={{
					color: "#3457DC",
				}}
			/>
			{children}
		</Backdrop>
	);
};

export default Loader;
