import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";
import DataGridTable from "components/Table/DataGrid";
import { jobListcolumns } from "pages/job/addJobUtils";
import { taskListColumns } from "pages/task/addTaskUtils";
import {
	getDashboardDetails,
	getUserProfileDetails,
} from "selectors/appSelector";
import { AgChartsReact } from "ag-charts-react";
import { fetchDashboardData } from "actions/appActions";
import { getProjects } from "selectors/projectSelector";
import get from "lodash/get";
import GeoChart from "components/Map/GeoChart";
import { useNavigate } from "react-router-dom";
import { convertCamelToNormal } from "core/utils/appUtils";
import "./dashboard.scss";

export const Dashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userProfile = useSelector(getUserProfileDetails);
	const dashboardData = useSelector(getDashboardDetails);
	const { projectsList } = useSelector(getProjects);

	useEffect(() => {
		dispatch(fetchDashboardData());
	}, [dispatch]);

	const options = {
		data: projectsList,
		title: {
			text: "Projects Overview",
		},
		subtitle: {
			text: "project cost in U.S Dollars",
		},
		series: [
			{
				type: "bar",
				xKey: "projectName",
				yKey: "projectCostEstimated",
				yName: "Estimated Cost",
				columnWidth: 0.3,
				width: 50,
			},
			{
				type: "line",
				xKey: "projectName",
				yKey: "projectCostActual",
				yName: "Actual Cost",
				columnWidth: 0.3,
			},
		],
		legend: {
			position: "bottom",
		},
		axes: [
			{
				type: "category",
				position: "bottom",
			},
			{
				type: "number",
				position: "left",
			},
		],
	};

	const generateLatLng = () => {
		const geoData = projectsList.map((project) => {
			if ((project.latitude, project.longitude)) {
				return {
					name: project.projectName,
					coordinates: [project.latitude || "", project.longitude || ""],
					projectId: project.projectId,
					markerContent: (
						<div>
							<Typography className="body1">
								<strong>Project Name</strong>
							</Typography>
							<Typography
								variant="body1"
								className={"link-text"}
								onClick={() => navigate(`/projects/view/${project.projectId}`)}
							>
								{project.projectName}
							</Typography>
							<div className="d-flex flex-wrap">
								{[
									"customerName",
									"startDate",
									"projectCostActual",
									"stage",
								].map((key) => {
									return (
										<div className="m-0 col-4">
											<Typography className="body1">
												<strong>{convertCamelToNormal(key)}</strong>
											</Typography>
											<Typography
												style={{ wordBreak: "all" }}
												className="body1"
											>
												{project[key]}
											</Typography>
										</div>
									);
								})}
							</div>
						</div>
					),
				};
			}
		});
		return geoData;
	};

	return dashboardData && Object.keys(dashboardData).length > 0 ? (
		<>
			<div id="header">
				<Typography className="h1">Welcome {userProfile.firstName}</Typography>
			</div>
			<div id="projects-data">
				<div>Total Projects </div>
				<div className="d-flex flex-wrap">
					<div className="col-12 col-md-6 p-3">
						<div style={{ height: "400px", width: "100%" }}>
							<AgChartsReact options={options} />
						</div>
					</div>
					<div className="col-12 col-md-6 p-3">
						<div style={{ height: "400px", width: "100%" }}>
							<AgChartsReact
								options={{
									data: get(dashboardData, "projectStatus.projects"),
									series: [
										{
											type: "pie",
											angleKey: "count",
											labelKey: "status",
											innerRadiusOffset: "stage",
											legendItemKey: "status",
											sectorLabelKey: "count",
											sectorLabel: {
												color: "white",
												fontWeight: "bold",
												formatter: ({ params }) => {
													const total =
														params.angleValue * params.angleValueScale.total;
													const percentage = (
														(params.angleValue / total) *
														100
													).toFixed(2);
													return `${percentage}%`;
												},
											},
										},
									],
									title: {
										text: "Project Stage Distribution",
									},
									subtitle: {
										text: "Distribution of projects by stages",
									},
									legend: {
										position: "bottom",
									},
								}}
							/>
						</div>
					</div>
				</div>
				<div className="d-flex flex-wrap mt-5">
					<div style={{ width: "75%" }}>
						<Typography className="h2">
							Global Distribution of Projects
						</Typography>
						<GeoChart
							styleClasses={{ popup: "markerPopup" }}
							geoData={generateLatLng()}
						/>
					</div>
				</div>
			</div>
			<div id="dashboard-content">
				<div className="d-flex flex-wrap">
					<div className="col-12 col-md-6 p-3"></div>
					<div className="col-12 col-md-6 p-3"></div>
				</div>
				<div className="d-flex flex-wrap">
					<div className="col-12 col-md-6 p-3">
						<div style={{ height: "400px", width: "100%" }}>
							<AgChartsReact
								options={{
									data: get(dashboardData, "jobStatus.jobs"),
									series: [
										{
											type: "pie",
											angleKey: "count",
											labelKey: "status",
											innerRadiusOffset: "stage",
											legendItemKey: "status",
											sectorLabelKey: "count",
											sectorLabel: {
												color: "white",
												fontWeight: "bold",
												formatter: ({ params }) => {
													const total =
														params.angleValue * params.angleValueScale.total;
													const percentage = (
														(params.angleValue / total) *
														100
													).toFixed(2);
													return `${percentage}%`;
												},
											},
										},
									],
									title: {
										text: "Jobs Status",
									},
									subtitle: {
										text: "Distribution of jobs by status",
									},
									legend: {
										position: "bottom",
									},
								}}
							/>
						</div>
						<div>
							<Typography className="body1 mb-3">
								<strong>Total Jobs </strong>
							</Typography>
						</div>

						{/* <DataGridTable
							columns={jobListcolumns()}
							data={jobsList.slice(0, 10)}
							showToolbar={false}
							checkboxSelection={false}
							defineRowId={(row) => row.jobId}
							pagination={false}
						/> */}
					</div>
					<div className="col-12 col-md-6 p-3">
						<div style={{ height: "400px", width: "100%" }}>
							<AgChartsReact
								options={{
									data: get(dashboardData, "taskStatus.tasks"),
									series: [
										{
											type: "pie",
											angleKey: "count",
											labelKey: "status",
											innerRadiusOffset: "stage",
											legendItemKey: "status",
											sectorLabelKey: "count",
											sectorLabel: {
												color: "white",
												fontWeight: "bold",
												formatter: ({ params }) => {
													const total =
														params.angleValue * params.angleValueScale.total;
													const percentage = (
														(params.angleValue / total) *
														100
													).toFixed(2);
													return `${percentage}%`;
												},
											},
										},
									],
									title: {
										text: "Task Distribution",
									},
									subtitle: {
										text: "Distribution of tasks by status",
									},
									legend: {
										position: "bottom",
									},
								}}
							/>
						</div>
						<div>
							<Typography className="body1 mb-3">
								<strong>Top 10 Tasks </strong>
							</Typography>
						</div>
						{/* <DataGridTable
							columns={taskListColumns()}
							data={tasksList.slice(0, 10)}
							showToolbar={false}
							checkboxSelection={false}
							defineRowId={(row) => row.taskId}
							pagination={false}
						/> */}
					</div>
				</div>
			</div>
		</>
	) : (
		<></>
	);
};

export default Dashboard;
