// src/components/GeoChart.js
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Popup, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { Typography } from "@mui/material";

const GeoChart = ({ geoData, styleClasses }) => {
	const [zoomLevel, setZoomLevel] = useState(2);

	return (
		<MapContainer
			style={{ height: "400px", width: "100%" }}
			center={[39.8283, -98.5795]}
			zoom={3}
			scrollWheelZoom={false}
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			/>
			{/* <GeoJSON data={sampleGeoData} /> */}
			{geoData.map((marker, idx) => (
				<CircleMarker
					center={marker.coordinates}
					key={idx}
					position={marker.coordinates}
					radius={5}
					color="red"
					eventHandlers={{
						click: () => {
							console.log("Check the marker : ", marker);
						},
					}}
				>
					<Popup className={styleClasses.popup}>{marker.markerContent}</Popup>
				</CircleMarker>
			))}
		</MapContainer>
	);
};

export default GeoChart;
