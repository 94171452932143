import React, { useState, useEffect } from "react";
import AddEditJob from "./AddNewJob";
import { useDispatch, useSelector } from "react-redux";
import { jobDelete, jobGetAll } from "actions/jobActions";
import { getJobsList } from "selectors/jobSelectors";
import { Typography } from "@mui/material";
import BreadCrumb from "components/BreadCrumb";
import DataGridTable from "components/Table/DataGrid";

import { useNavigate } from "react-router-dom";
import AddEditTask from "pages/task/AddNewTask";
import get from "lodash/get";
import { jobListcolumns } from "./addJobUtils";
import ProjectContext from "context/ProjectContext";
import NoResultsFound from "components/NoResults/NoResultsFound";

export const Jobs = () => {
	const { selectedProjectContext } = React.useContext(ProjectContext);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { jobsList, totalCount } = useSelector(getJobsList);

	const [addNewJob, setAddNewJob] = useState(false);

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [editJob, setEditJob] = useState(false);
	const [addTask, setAddTask] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	console.log("Selected Context : ", selectedProjectContext);
	useEffect(() => {
		dispatch(
			jobGetAll({ ...pageModel, projectId: selectedProjectContext?.projectId })
		);
	}, [dispatch, pageModel, selectedProjectContext]);

	if (addTask) {
		return (
			<AddEditTask
				onCancel={() => setAddTask(false)}
				parentId={{ jobId: selectedJob.id }}
			/>
		);
	}

	return (
		<div className="jobs-page">
			{addNewJob ? (
				<AddEditJob
					onCancel={() => setAddNewJob(false)}
					jobId={editJob}
					selectedJob={selectedJob}
					title={get(selectedJob, "data.projectName", null)}
				/>
			) : (
				<>
					<div className="projects-header-section">
						<div>
							<Typography className="h1">Jobs</Typography>
							<BreadCrumb />
						</div>
					</div>
					<div className="projects-content-section">
						{totalCount > 0 ? (
							<DataGridTable
								pagination={totalCount > 10}
								totalCount={totalCount}
								columns={jobListcolumns({
									setEditJob,
									setAddTask,
									setAddNewJob,
									setSelectedJob,
									onJobDelete: (jobId) => {
										dispatch(jobDelete(jobId, pageModel));
									},
								})}
								data={jobsList}
								defineRowId={(row) => {
									return row.jobId;
								}}
								paginationModel={pageModel}
								onPaginationChange={(props) => {
									setPageModel(props);
								}}
								onSelect={(props) => {
									setSelectedRows(props);
								}}
								selectedRows={selectedRows}
								onDeleteSelection={() => {
									console.log("Delete Selection : ", selectedRows);
								}}
								onRowClick={(row) => {
									navigate(`/jobs/view/${row.id}`);
								}}
							/>
						) : (
							<NoResultsFound />
						)}
					</div>
				</>
			)}

			{!addNewJob && (
				<div className="page-footer  fixed-footer align-content-center">
					<div className="d-flex justify-content-end ">
						<button
							className="primaryButton addProjectButton"
							style={{ width: "8rem" }}
							onClick={() => {
								setAddNewJob(!addNewJob);
							}}
						>
							{"+ Add Job"}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

// const oldCode = (
// 	<div className="">
// 		<div className="projects-header-section">
// 			<div>
// 				<Typography className="h1">Jobs</Typography>
// 				<BreadCrumb />
// 			</div>
// 			<div className="d-flex justify-content-end">
// 				<button
// 					className="primaryButton addProjectButton"
// 					style={{ width: "8rem" }}
// 					onClick={() => {
// 						addNewJob ? setAddNewJob(false) : setAddNewJob(true);
// 					}}
// 				>
// 					{addNewJob ? "Cancel" : "+ Add New Job"}
// 				</button>
// 			</div>
// 		</div>
// 		<div className="projects-content-section">
// 			{addNewJob ? (
// 				<AddNewJob setAddNewJob={() => setAddNewJob(false)} />
// 			) : (
// 				<>
// 					<Paper sx={{ width: "100%", overflow: "hidden" }} className="mt-5">
// 						<TableContainer sx={{ maxHeight: 440 }}>
// 							<Table stickyHeader aria-headerName="sticky table">
// 								<TableHead>
// 									<TableRow>
// 										{columns.map((column) => (
// 											<TableCell
// 												key={column.id}
// 												align={column.align}
// 												style={{ minWidth: column.minWidth }}
// 											>
// 												{column.headerName}
// 											</TableCell>
// 										))}
// 									</TableRow>
// 								</TableHead>
// 								<TableBody>
// 									{jobsList
// 										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
// 										.map((row) => {
// 											return (
// 												<TableRow
// 													hover
// 													role="checkbox"
// 													tabIndex={-1}
// 													key={row.code}
// 												>
// 													{columns.map((column) => {
// 														const value = row[column.id];
// 														return (
// 															<TableCell key={column.id} align={column.align}>
// 																{column.format ? column.format(value) : value}
// 															</TableCell>
// 														);
// 													})}
// 												</TableRow>
// 											);
// 										})}
// 								</TableBody>
// 							</Table>
// 						</TableContainer>
// 						<TablePagination
// 							rowsPerPageOptions={[10, 25, 100]}
// 							component="div"
// 							count={jobsList.length}
// 							rowsPerPage={rowsPerPage}
// 							page={page}
// 							onPageChange={handleChangePage}
// 							onRowsPerPageChange={handleChangeRowsPerPage}
// 						/>
// 					</Paper>
// 				</>
// 			)}
// 		</div>
// 	</div>
// );

export default Jobs;
