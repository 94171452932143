import React from "react";
import { Typography } from "@mui/material";
import { convertCamelToNormal } from "core/utils/appUtils";
import { useSelector } from "react-redux";
import { getProjectData } from "selectors/projectSelector";
import { getTasks } from "selectors/taskSelectors";
import { getJobsList } from "selectors/jobSelectors";
import { AgChartsReact } from "ag-charts-react";

export const Overview = () => {
	const projectData = useSelector(getProjectData);
	const tasksData = useSelector(getTasks);
	const jobsData = useSelector(getJobsList);

	return (
		<>
			<div className="m-2 mt-3 mb-3">
				<Typography className="h2">Project Details:</Typography>
				<Typography className="body1">{projectData.projectDetails}</Typography>
			</div>
			<div className="d-flex flex-wrap">
				<div className={"col-7 col-xs-6"}>
					<div className="d-flex flex-wrap">
						{Object.keys(projectData).map((key) => {
							return (
								<div className="m-2 col-3">
									<Typography className="body1">
										<strong>{convertCamelToNormal(key)}</strong>
									</Typography>
									<Typography style={{ wordBreak: "all" }} className="body1">
										{key === "siteLocation" ? (
											<a
												href={projectData[key]}
												target="_blank"
												rel="noreferrer"
												className="link-text"
											>
												View Location
											</a>
										) : (
											projectData[key]
										)}
									</Typography>
								</div>
							);
						})}
					</div>
				</div>
				<div className={"col-5 col-xs-6"}>
					<div
						style={{
							// height: "10rem",
							width: "100%",
							border: "1px solid",
							borderRadius: "9px",
						}}
					>
						<div>
							<Typography className="body1 m-2">
								Total Jobs: {jobsData.totalCount}
							</Typography>
							<div style={{ height: "200px", width: "100%" }}>
								<AgChartsReact
									options={{
										data: jobsData.jobsList,
										series: [
											{
												type: "pie",
												angleKey: "count",
												labelKey: "status",
												innerRadiusOffset: "stage",
												legendItemKey: "status",
												sectorLabelKey: "count",
												sectorLabel: {
													color: "white",
													fontWeight: "bold",
													formatter: ({ params }) => {
														const total =
															params.angleValue * params.angleValueScale.total;
														const percentage = (
															(params.angleValue / total) *
															100
														).toFixed(2);
														return `${percentage}%`;
													},
												},
											},
										],
										title: {
											text: "Jobs Status",
										},
										subtitle: {
											text: "Distribution of jobs by status",
										},
										legend: {
											position: "bottom",
										},
									}}
								/>
							</div>
						</div>
						<div>
							<Typography className="body1 m-2">
								Total Tasks: {tasksData.totalCount}
							</Typography>
							<div style={{ height: "200px", width: "100%" }}>
								<AgChartsReact
									options={{
										data: tasksData.tasksList,
										series: [
											{
												type: "pie",
												angleKey: "count",
												labelKey: "status",
												innerRadiusOffset: "stage",
												legendItemKey: "status",
												sectorLabelKey: "count",
												sectorLabel: {
													color: "white",
													fontWeight: "bold",
													formatter: ({ params }) => {
														const total =
															params.angleValue * params.angleValueScale.total;
														const percentage = (
															(params.angleValue / total) *
															100
														).toFixed(2);
														return `${percentage}%`;
													},
												},
											},
										],
										title: {
											text: "Task Distribution",
										},
										subtitle: {
											text: "Distribution of tasks by status",
										},
										legend: {
											position: "bottom",
										},
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Overview;
