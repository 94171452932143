import React from "react";
import { TextField, MenuItem, Box, Chip, Typography } from "@mui/material";
import { isUndefined } from "lodash";

export const FormInputField = ({
	field,
	value,
	formik,
	className = "",
	...rest
}) => {
	return (
		<div
			className={`form-field-wrapper ${field.className}`}
			style={{ width: field.width }}
		>
			<FormLabel text={field.label} required={field.required} />
			<TextField
				key={field.key}
				id={field.key}
				type={field.type}
				select={field.select}
				name={field.key}
				fullWidth
				variant="outlined"
				placeholder={field.placeholder}
				className={`registration-formField ${className || field.className}`}
				value={value}
				disabled={field.disabled}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched[field.key] && Boolean(formik.errors[field.key])}
				helperText={formik.touched[field.key] && formik.errors[field.key]}
				SelectProps={{
					displayEmpty: true,
					multiple: field.multiple,
					renderValue: (selected) => {
						if (field.multiple) {
							if (selected && selected.length === 0) {
								return <em>{field.placeholder}</em>;
							}
							return (
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: 0.5,
										marginTop: "-0.75rem",
									}}
								>
									{selected &&
										selected.length > 0 &&
										selected.map((value) => (
											<Chip
												key={value}
												label={value}
												style={{ fontSize: "12px" }}
												variant="outlined"
												onDelete={() => {
													formik.setFieldValue(
														field.key,
														selected.filter((item) => item !== value)
													);
												}}
											/>
										))}
								</Box>
							);
						} else {
							return (
								<Typography>
									{typeof field.options[0] === "string"
										? selected
										: field.options.find((option) => option.key === selected)
												?.text}
								</Typography>
							);
						}
					},
				}}
				{...rest}
			>
				<MenuItem value={""} disabled>
					{field.placeholder}
				</MenuItem>
				{field.options &&
					field.options.map((option) => {
						return !isUndefined(option.key) ? (
							<MenuItem
								key={option.key}
								value={option.key}
								selected={formik.values[field.key] === option.key}
							>
								{option.text}
							</MenuItem>
						) : (
							<MenuItem value={option}>{option}</MenuItem>
						);
					})}
			</TextField>
		</div>
	);
};

export const RequiredSup = () => {
	return <sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>;
};

export const FormLabel = ({ className, required, text }) => {
	return (
		<p className={`form-label ${className}`}>
			<label>
				{text}
				{required && <RequiredSup />}
			</label>
		</p>
	);
};

export default FormInputField;
