import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";
import rootReducer from "../reducers/index";

// Configure store with reducers and middleware, including Redux DevTools Extension
const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(reduxThunk))
);

export default store;
