import React from "react";
import { Typography } from "@mui/material";
import DataGridTable from "components/Table/DataGrid";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getJobsList } from "selectors/jobSelectors";
import { getTasks } from "selectors/taskSelectors";
import { getStatus } from "core/utils/appUtils";
export const TaskManager = () => {
	const navigate = useNavigate();
	const { tasksList } = useSelector(getTasks);
	const { jobsList } = useSelector(getJobsList);

	return (
		<>
			<div>
				<div className="d-flex">
					<div className="col-12 col-md-6 p-3">
						<Typography className="h3">Jobs</Typography>
						<DataGridTable
							columns={[
								{
									field: "jobName",
									headerName: "Job Name",
									width: 200,
									sortable: false,
									editable: false,
									resizable: false,
								},
								{
									field: "assignedTeam",
									headerName: "Assigned Team",
									width: 200,
									sortable: false,
									editable: false,
									resizable: false,
								},
								{
									field: "status",
									headerName: "Job Status",
									width: 200,
									sortable: false,
									editable: false,
									resizable: false,
									renderCell: (params) => {
										return (
											<>
												<div>
													{getStatus(params.row.status || "Pending")}
													<span style={{ marginLeft: "5px" }}>
														{params.row.status}
													</span>
												</div>
											</>
										);
									},
								},
							]}
							data={jobsList}
							checkboxSelection={false}
							loading={false}
							pageSize={10}
							rowsPerPageOptions={[10, 25, 50, 100]}
							defineRowId={(row) => row.jobId}
							showToolbar={false}
							pagination={false}
							onRowClick={(row) => navigate(`/jobs/view/${row.row.jobId}`)}
						/>
					</div>
					<div className="col-12 col-md-6 p-3">
						<Typography className="h3">Tasks</Typography>
						<DataGridTable
							columns={[
								{
									field: "header",
									headerName: "Job Name",
									width: 150,
									sortable: false,
									editable: false,
									resizable: false,
								},
								{
									field: "job",
									headerName: "Job Name",
									width: 150,
									sortable: false,
									editable: false,
									resizable: false,
									renderCell: (params) => {
										return params.row.job.jobName;
									},
								},
								{
									field: "taskStatus",
									headerName: "Task Status",
									width: 150,
									sortable: false,
									editable: false,
									resizable: false,
									renderCell: (params) => {
										return (
											<>
												<div>
													{getStatus(
														params.row.taskStatus.statusName || "Pending"
													)}
													<span style={{ marginLeft: "5px" }}>
														{params.row.taskStatus.statusName}
													</span>
												</div>
											</>
										);
									},
								},
							]}
							data={tasksList}
							checkboxSelection={false}
							loading={false}
							pageSize={10}
							showToolbar={false}
							pagination={false}
							rowsPerPageOptions={[10, 25, 50, 100]}
							defineRowId={(row) => row.taskId}
							onRowClick={(row) => navigate(`/tasks/view/${row.row.taskId}`)}
						/>
					</div>
				</div>
			</div>{" "}
		</>
	);
};

export default TaskManager;
