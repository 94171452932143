import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import axios from "axios";
import {
	PROPERTY_LOADING,
	PROPERTY_GET_ALL_SUCCESS,
	PROPERTY_ERROR,
	PROPERTY_CLEAR_SNACKBAR,
} from "../constants/types";

export const addNewProperty = (formData, onSuccess) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.ADD_NEW_PROPERTY,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/property`,
				body: {
					...formData,
				},
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.ADD_NEW_PROPERTY_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Property Added Successfully",
							loading: false,
						},
					});
					onSuccess();
					// dispatch(propertyGetAll());
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.ADD_NEW_PROPERTY_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const propertyGetAll = () => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: PROPERTY_LOADING,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/property`,
			})
				.then((response) => {
					dispatch({
						type: PROPERTY_GET_ALL_SUCCESS,
						payload: {
							propertyList: response.data.items,
							totalCount: response.data.totalCount,
							loading: false,
							status: "success",
							text: "Get All PROPERTY data successfully.",
						},
					});
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: PROPERTY_ERROR,
						payload: {
							status: "error",
							text: "Error occured during getting PROPERTY data.",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const propertyDelete = (property) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: PROPERTY_LOADING,
				payload: {
					loading: true,
				},
			});
			axios
				.delete(`${server_url}/property/${property.PROPERTY_ID}`, {})
				.then((response) => {
					axios
						.get(`${server_url}/property`, {})
						.then((response) => {
							dispatch({
								type: PROPERTY_GET_ALL_SUCCESS,
								payload: {
									propertyList: response.data,
									loading: false,
									status: "success",
									text: "Delete PROPERTY data successfully.",
								},
							});
							resolve();
						})
						.catch((e) => {
							dispatch({
								type: PROPERTY_ERROR,
								payload: {
									text: "Error occured during getting PROPERTY data.",
									status: "error",
									loading: false,
								},
							});
							resolve();
						});
				})
				.catch((e) => {
					dispatch({
						type: PROPERTY_ERROR,
						payload: {
							text: "Error occured during deleteing PROPERTY data.",
							status: "error",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const propertyAddOrUpdate = (property, state) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: PROPERTY_LOADING,
				payload: {
					loading: true,
				},
			});
			if (state === "add") {
				axios
					.post(`${server_url}/property`, property)
					.then((response) => {
						axios
							.get(`${server_url}/property`)
							.then((response) => {
								dispatch({
									type: PROPERTY_GET_ALL_SUCCESS,
									payload: {
										propertyList: response.data,
										loading: false,
										status: "success",
										text: "Add PROPERTY data successfully.",
									},
								});
								resolve();
							})
							.catch((e) => {
								dispatch({
									type: PROPERTY_ERROR,
									payload: {
										text: "Error occured during getting PROPERTY data.",
										status: "error",
										loading: false,
									},
								});
								resolve();
							});
					})
					.catch((e) => {
						dispatch({
							type: PROPERTY_ERROR,
							payload: {
								text: "Error occured during adding PROPERTY data.",
								status: "error",
								loading: false,
							},
						});
						resolve();
					});
			} else if (state === "edit") {
				axios
					.put(`${server_url}/property`, property)
					.then((response) => {
						axios
							.get(`${server_url}/property`)
							.then((response) => {
								dispatch({
									type: PROPERTY_GET_ALL_SUCCESS,
									payload: {
										propertyList: response.data,
										loading: false,
										status: "success",
										text: "Update PROPERTY data successfully.",
									},
								});
								resolve();
							})
							.catch((e) => {
								dispatch({
									type: PROPERTY_ERROR,
									payload: {
										text: "Error occured during getting PROPERTY data.",
										status: "error",
										loading: false,
									},
								});
								resolve();
							});
					})
					.catch((e) => {
						dispatch({
							type: PROPERTY_ERROR,
							payload: {
								text: "Error occured during updating PROPERTY data.",
								status: "error",
								loading: false,
							},
						});
						resolve();
					});
			}
		});
	};
};

export const propertyClearShowSnackbar = () => {
	return (dispatch, getState) => {
		dispatch({
			type: PROPERTY_CLEAR_SNACKBAR,
		});
	};
};
