import { Tooltip, IconButton, Menu } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import "./menu.scss";

const BurgerMenu = ({ onClick, toolTip, children }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		onClick && onClick();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Box sx={{ display: "flex", justifyContent: "end" }}>
				<Tooltip title={toolTip}>
					<IconButton
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? "portfolio-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						data-testid="burger-menu-icon-button"
					>
						<MoreHorizSharpIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="burger-menu"
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					className: "burger-menu-paper",
					style: {
						backgroundColor: "white",
						width: "10rem",
					},
					sx: {
						overflow: "visible",
						mt: 0.2,
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "#eeeeee",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{children}
			</Menu>
		</React.Fragment>
	);
};

export default BurgerMenu;
