import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./properties.scss";
import { useFormik } from "formik";
import {
	TextField,
	MenuItem,
	Box,
	Chip,
	Typography,
	IconButton,
} from "@mui/material";
import { addPropertyForm } from "./addPropertyUtils";
import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";
import GeolocationField, {
	LocationField,
} from "components/Location/GeoLocation";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import { addNewProperty } from "actions/propertyActions";
import { useNavigate } from "react-router-dom";
import { FormLabel } from "components/TextFields/FormInputField";

export const AddNewProperty = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [showLocationModal, setShowLocationModal] = useState(false);

	const { steps, formFields, validationSchema, stepDescription } =
		addPropertyForm;
	const initialState = addPropertyForm.defaultState();

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			console.log("Check the Onsubmit : ");
		},
	});
	const handleNext = () => {
		const currentFields = formFields[`step${activeStep}`]
			.filter((field) => field.required)
			.map((field) => field.key);
		const currentTouchedFields = pick(formik.touched, currentFields);
		const currentErrorFields = pick(formik.errors, currentFields);
		if (activeStep + 1 < steps.length) {
			if (
				currentFields.length === keys(currentTouchedFields).length &&
				keys(currentErrorFields).length === 0
			) {
				setActiveStep(activeStep + 1);
			}
		}
		if (activeStep + 1 === steps.length) {
			if (keys(formik.errors).length === 0) {
				dispatch(
					addNewProperty(formik.values, (payload) => {
						navigate("/properties");
					})
				);
				// saveProperty(formik.values, (payload) => {
				// setCompanyDataSaved(payload);
				// if (payload.status === "error") {
				// 	setShowAlert(true);
				// }
				// })
			}
		}
	};
	const handleLocationInput = () => {
		setShowLocationModal(true);
	};
	return (
		<div className="mt-2 p-2">
			<Typography variant="h6" className="text-left m-2">
				Add New Property
			</Typography>
			<ModelDialog
				open={showLocationModal}
				fullWidth={true}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography variant="h6">{"Set Property Location"}</Typography>
						<IconButton onClick={() => setShowLocationModal(false)}>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					<div>
						<div>
							<GeolocationField
								handleMarkerClick={(locationLink) => {
									formik.setFieldValue("propertyLocation", locationLink);
								}}
							/>
						</div>
						<div style={{ textAlign: "right" }}>
							<button
								className={`save-details-button m-4 primaryButton`}
								onClick={() => {
									setShowLocationModal(false);
								}}
							>
								{"Save"}
							</button>
						</div>
					</div>
				}
			/>
			<div className="form-section">
				<div>
					<Typography variant="subtitle1" className="text-left m-1">
						{`${activeStep + 1}. ${
							stepDescription[`step${activeStep}`].heading
						}`}
					</Typography>
					<div>
						<p className="m-1">
							{stepDescription[`step${activeStep}`].subHeading}
						</p>
					</div>
				</div>
				<div>
					<form onSubmit={formik.handleSubmit}>
						<div className="stepContent container row">
							{formFields[`step${activeStep}`].map((field, index) => {
								const extraProps = get(field, "extraInputProps", {});
								return (
									<div
										className={`${field.className} mt-3`}
										style={{ width: field.width }}
									>
										<FormLabel text={field.label} required={field.required} />
										{field.key === "propertyLocation" ? (
											<div className="google-location-field">
												<LocationField
													onSelect={(payload) => {
														formik.setFieldValue(
															field.key,
															payload.locationUrl
														);
														// formik.setFieldValue("locationUrl", payload.locationUrl);
													}}
												/>
												{/* <Typography
													style={{ wordBreak: "break-all" }}
													onClick={() => {
														handleLocationInput();
													}}
													className="link-text"
												>
													{formik.values[field.key] ||
														"Set the Property Location on the Map"}
												</Typography> */}
											</div>
										) : (
											<TextField
												key={field.key}
												id={field.key}
												type={field.type}
												select={field.select}
												name={field.key}
												fullWidth
												variant="outlined"
												placeholder={field.placeholder}
												className={`registration-formField `}
												value={formik.values[field.key]}
												disabled={field.disabled}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={
													formik.touched[field.key] &&
													Boolean(formik.errors[field.key])
												}
												helperText={
													formik.touched[field.key] && formik.errors[field.key]
												}
												SelectProps={{
													displayEmpty: true,
													multiple: field.multiple,
													renderValue: (selected) => {
														if (field.multiple) {
															if (selected && selected.length === 0) {
																return <em>{field.placeholder}</em>;
															}
															return (
																<Box
																	sx={{
																		display: "flex",
																		flexWrap: "wrap",
																		gap: 0.5,
																		marginTop: "-0.75rem",
																	}}
																>
																	{selected &&
																		selected.length > 0 &&
																		selected.map((value) => (
																			<Chip
																				key={value}
																				label={value}
																				style={{ fontSize: "12px" }}
																				variant="outlined"
																				onDelete={() => {
																					formik.setFieldValue(
																						field.key,
																						selected.filter(
																							(item) => item !== value
																						)
																					);
																				}}
																			/>
																		))}
																</Box>
															);
														} else {
															return <Typography>{selected}</Typography>;
														}
													},
												}}
												{...extraProps}
											>
												<MenuItem value={""} disabled>
													{field.placeholder}
												</MenuItem>
												{field.options &&
													field.options.map((option) => {
														return <MenuItem value={option}>{option}</MenuItem>;
													})}
											</TextField>
										)}
									</div>
								);
							})}
							<div style={{ textAlign: "right" }}>
								<div className="d-flex justify-content-between">
									<div>
										{activeStep !== 0 && (
											<button
												type="submit"
												className={`save-details-button m-4 primaryButton`}
												onClick={() => {
													setActiveStep(activeStep - 1);
												}}
											>
												{"< Previous"}
											</button>
										)}
									</div>
									<div className="float-right">
										<button
											type="submit"
											className={`save-details-button m-4 primaryButton`}
											onClick={() => {
												handleNext();
											}}
										>
											{activeStep + 1 === steps.length ? "Save" : "Next >"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewProperty;
