import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import ProjectContext from "context/ProjectContext";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { getProjects } from "selectors/projectSelector";

export const GlobalProjectSelector = () => {
	const { selectedProjectContext, setProjectContext } =
		useContext(ProjectContext);
	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
	const { projectsList, projectsLoading } = useSelector(getProjects);

	return (
		<>
			<Autocomplete
				value={selectedProjectContext || ""}
				onChange={(event, value) => {
					setProjectContext(value);
				}}
				id="asynchronous-demo"
				className="form-field header-project-selector"
				open={autoCompleteOpen}
				onOpen={() => {
					setAutoCompleteOpen(true);
				}}
				onClose={() => {
					setAutoCompleteOpen(false);
					console.log("Check on Close ");
				}}
				isOptionEqualToValue={(option, value) =>
					option.projectId === value.projectId
				}
				// filterOptions={(x) => x}
				getOptionLabel={(option) => option.projectName}
				options={projectsList}
				loading={projectsLoading}
				disableClearable
				renderInput={(params) => (
					<TextField
						{...params}
						className="form-field"
						onChange={(event, value) => {
							console.log("On Text Field : ", event.target.value);
							const textLen = event.target.value.length;
							if (textLen >= 3) {
								console.log("Fetching the record");
							}
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{projectsLoading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</>
	);
};

export default GlobalProjectSelector;
