import { ActionConstants } from "constants";

const initialState = {
	usersList: [],
	userData: {},
	usersLoading: false,
	status: "success",
	text: "",
	showSnackbar: false,
	timesheetData: [],
	totalTimesheetEntries: 0,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.GET_USERS:
			return {
				...state,
				usersLoading: action.payload.loading,
			};
		case ActionConstants.GET_USERS_SUCCESS:
			return {
				...state,
				usersList: action.payload.response.items,
				totalCount: action.payload.response.totalCount,
				usersLoading: action.payload.loading,
			};
		case ActionConstants.GET_USERS_ERROR:
			return {
				...state,
				usersLoading: action.payload.loading,
			};
		case ActionConstants.ADD_NEW_USER_SUCCESS:
			return {
				...state,
				usersList: action.payload.response,
			};
		case ActionConstants.ADD_NEW_USER_ERROR:
			return {
				...state,
			};
		case ActionConstants.INVITE_NEW_USER_SUCCESS:
			return {
				...state,
				userData: action.payload.response,
			};
		case ActionConstants.INVITE_NEW_USER_ERROR:
			return {
				...state,
			};
		case ActionConstants.GET_TIME_SHEET_SUCCESS:
			return {
				...state,
				timesheetData: action.payload.response,
				totalTimesheetEntries: action.payload.totalCount,
			};
		default:
			return state || {};
	}
};

export default userReducer;
