import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";

export const fetchDashboardData = () => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_DASHBOARD_DATA,
				payload: {
					loading: true,
				},
			});

			request({
				method: "GET",
				url: `${server_url}/project/chartData`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_DASHBOARD_DATA_SUCCESS,
						payload: response.data,
					});
					// dispatch(ProjectGetAll());
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.ADD_NEW_PROJECT_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
