import { combineReducers } from "redux";
import appReducer from "./appReducer";
import companyReducer from "./companyReducer";
import propertyReducer from "./propertyReducer";
import projectReducer from "./projectReducer";

import activityReducer from "./activityReducer";
import allocationReducer from "./allocationReducer";
import change_orderReducer from "./change_orderReducer";
import client_selectionReducer from "./client_selectionReducer";
import connectionReducer from "./connectionReducer";
import connection_statusReducer from "./connection_statusReducer";
import contactReducer from "./contactReducer";
import contract_typeReducer from "./contract_typeReducer";
import customerReducer from "./customerReducer";
import daily_logReducer from "./daily_logReducer";
import employeeReducer from "./employeeReducer";
import invoiceReducer from "./invoiceReducer";
import jobReducer from "./jobReducer";
import job_employeeReducer from "./job_employeeReducer";
import leadReducer from "./leadReducer";
import lead_activityReducer from "./lead_activityReducer";
import lead_statusReducer from "./lead_statusReducer";
import organizationReducer from "./organizationReducer";
import organization_statusReducer from "./organization_statusReducer";
import organization_typeReducer from "./organization_typeReducer";
import paymentReducer from "./paymentReducer";
import purchase_orderReducer from "./purchase_orderReducer";
import roleReducer from "./roleReducer";
import taskReducer from "./taskReducer";
import warranty_supportReducer from "./warranty_supportReducer";

import customer_typeReducer from "./customer_typeReducer";
import customer_statusReducer from "./customer_statusReducer";
import task_statusReducer from "./task_statusReducer";
import purchase_order_statusReducer from "./purchase_order_statusReducer";
import warranty_statusReducer from "./warranty_statusReducer";
import change_order_statusReducer from "./change_order_statusReducer";
import invoice_statusReducer from "./invoice_statusReducer";
import userReducer from "./userReducer";

// Combine all reducers as root reducer
export default combineReducers({
	app: appReducer,
	company: companyReducer,

	// activity: activityReducer,
	// allocation: allocationReducer,
	// change_order: change_orderReducer,
	// client_selection: client_selectionReducer,
	// connection: connectionReducer,
	// connection_status: connection_statusReducer,
	// contact: contactReducer,
	// contract_type: contract_typeReducer,
	customer: customerReducer,
	// daily_log: daily_logReducer,
	employee: employeeReducer,
	// invoice: invoiceReducer,
	job: jobReducer,
	// job_employee: job_employeeReducer,
	// lead: leadReducer,
	// lead_activity: lead_activityReducer,
	// lead_status: lead_statusReducer,
	// organization: organizationReducer,
	// organization_status: organization_statusReducer,
	// organization_type: organization_typeReducer,
	payment: paymentReducer,
	// purchase_order: purchase_orderReducer,
	// purchase_order_status: purchase_order_statusReducer,
	// role: roleReducer,
	task: taskReducer,
	// warranty_support: warranty_supportReducer,
	customer_type: customer_typeReducer,
	customer_status: customer_statusReducer,
	property: propertyReducer,
	project: projectReducer,
	user: userReducer,
	// task_status: task_statusReducer,
	// warranty_status: warranty_statusReducer,
	// change_order_status: change_order_statusReducer,
	// invoice_status: invoice_statusReducer,
});
