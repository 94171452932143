import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { InputAdornment } from "@mui/material";
import { AssignmentOutlined, Delete, EditOutlined } from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import moment from "moment";
import { MenuItem } from "@mui/material";

const getTextContent = (key) => getText(`jobs.addJobs.${key}`);

const addJobValidationSchema = yup.object({
	jobName: yup.string("Enter Job Name").required("Job Name is required"),
	description: yup
		.string("Enter Description")
		.required("Description is required"),
	plannedStartDate: yup
		.string("Enter Planned Start Date")
		.required("Start Date is required"),
	plannedEndDate: yup
		.string("Enter Planned End Date")
		.required("End is required"),
	actualStartDate: yup
		.string("Enter Actual Start Date")
		.required("Actual Start Date is required"),
	actualEndDate: yup
		.string("Enter Actual End Date")
		.required("Actual End Date is required"),
	assignedTeam: yup
		.string("Enter Assigned Team")
		.required("Assigned Team is required"),
	status: yup.string("Enter Status").required("Status is required"),
	priority: yup.string("Enter Priority").required("Priority is required"),

	budget: yup
		.number("Enter total Budget")
		.required("budget count is required")
		.typeError("Please enter a valid number")
		.integer("budget count must be an integer"),

	costToDate: yup
		.number("Enter cost till now")
		.required("Cost is required")
		.typeError("Please enter a valid number")
		.integer("Cost must be an integer"),
});
export const addJobForm = {
	steps: Array.from({ length: 1 }, (_, index) =>
		getText(`profile.contractor.step${index + 1}`)
	),
	stepDescription: {
		step0: {
			subHeading:
				"Please enter all the details of the job that are required to be filled.",
		},
	},
	formFields: {
		step0: [
			{
				key: "jobName",
				placeholder: "Job Name",
				label: getTextContent("jobName"),
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "description",
				placeholder: getTextContent("description"),
				label: getTextContent("description"),
				type: "textArea",
				required: true,
				width: "100%",
			},
			{
				key: "plannedStartDate",
				placeholder: getTextContent("plannedStartDate"),
				label: getTextContent("plannedStartDate"),
				type: "dateField",
				required: true,
				width: "50%",
			},
			{
				key: "plannedEndDate",
				placeholder: getTextContent("plannedEndDate"),
				label: getTextContent("plannedEndDate"),
				type: "dateField",
				required: true,
				width: "50%",
			},
			{
				key: "actualStartDate",
				placeholder: getTextContent("actualStartDate"),
				label: getTextContent("actualStartDate"),
				type: "dateField",
				required: true,
				width: "50%",
			},
			{
				key: "actualEndDate",
				placeholder: getTextContent("actualEndDate"),
				label: getTextContent("actualEndDate"),
				type: "dateField",
				required: true,
				width: "50%",
			},

			{
				key: "assignedTeam",
				placeholder: "Assigned Team",
				label: getTextContent("assignedTeam"),
				type: "select",
				width: "50%",
				select: true,
				required: true,
				options: [
					"Plumbing",
					"Electrician",
					"Painter",
					"Landscaping",
					"Roofing",
					"General Contractor",
					"Architect",
					"Interior Designer",
					"Exterior Designer",
					"Flooring",
					"Windows & Doors",
					"Concrete",
					"Demolition",
					"Excavation",
					"Foundation",
				],
			},
			{
				key: "dependencies",
				placeholder: "Enter Dependencies",
				label: getTextContent("dependencies"),
				type: "text",
				width: "50%",
				extraInputProps: {
					multiline: true,
				},
			},

			{
				key: "status",
				placeholder: "Status of the Job",
				label: getTextContent("status"),
				type: "select",
				select: true,
				required: true,
				options: ["Planning", "Pending", "In Progress", "Completed"],
				width: "50%",
			},
			{
				key: "priority",
				placeholder: "Priority if Job",
				label: getTextContent("priority"),
				type: "select",
				select: true,
				required: true,
				options: [
					{ key: 0, text: "Low" },
					{ key: 1, text: "Medium" },
					{ key: 2, text: "High" },
				],
				width: "50%",
			},
			{
				key: "budget",
				placeholder: "Enter Budget for Job",
				label: getTextContent("budget"),
				type: "text",
				width: "50%",
				formatter: (value) => parseInt(value),
				extraInputProps: {
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">{"$"}</InputAdornment>
						),
					},
				},
			},
			{
				key: "costToDate",
				placeholder: "Enter Cost till Date",
				label: getTextContent("costToDate"),
				type: "text",
				width: "50%",
				formatter: (value) => {
					console.log("CostToDate ", value);
					parseInt(value);
				},
				extraInputProps: {
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">{"$"}</InputAdornment>
						),
					},
				},
			},
			{
				key: "resourcesNeeded",
				placeholder: "Resources Needed",
				label: getTextContent("resourcesNeeded"),
				type: "text",
				width: "100%",
				select: true,
				required: true,
				options: [
					{ key: 0, text: "No" },
					{ key: 1, text: "Yes" },
				],
			},
			{
				key: "notes",
				placeholder: "Enter Notes",
				label: getTextContent("notes"),
				type: "text",
				width: "100%",
				extraInputProps: {
					multiline: true,
				},
			},
		],
	},
	validationSchema: addJobValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		} else {
			const dynamicInitialState = Object.fromEntries(
				Object.keys(addJobValidationSchema.fields).map((key) => [key, ""])
			);

			return {
				...dynamicInitialState,
				costToDate: "0",
			};
		}
	},
};

export const priority = {
	0: "Low Priority",
	1: "Medium Priority",
	2: "High Priority",
};
export const jobListcolumns = (props = {}) => [
	{ field: "jobId", headerName: "S.no", width: 50 },
	{ field: "jobName", headerName: "Property Name", width: 200 },
	{
		field: "status",
		headerName: "Status",
		width: 100,
	},
	{
		field: "priority",
		headerName: "Priority",
		width: 100,
		valueFormatter: (value) => priority[value],
	},
	{
		field: "budget",
		headerName: "Budget",
	},

	{
		field: "planningStartDate",
		headerName: "Plan Date",

		valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	},
	{
		field: "actualStartDate",
		headerName: "Actual Date",

		valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	},
	{
		field: "planningEndDate",
		headerName: "End Date",

		valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	},
	// {
	// 	field: "actualEndDate",
	// 	headerName: "Actual End Date",

	// 	valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
	// },
	{
		field: "actions",
		type: "actions",
		align: "left",
		getActions: (params) => [
			<BurgerMenu>
				<MenuItem
					onClick={() => {
						console.log("Check the Params : ", params);
						props.setEditJob(true);
						props.setAddNewJob(true);
						props.setSelectedJob({ id: params.id, data: params.row });
					}}
				>
					<EditOutlined /> <p className="m-0">Edit Job</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.setAddTask(true);
						props.setSelectedJob({
							id: params.id,
							data: params.row,
						});
					}}
				>
					<AssignmentOutlined /> <p className="m-0">Add Task</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.onJobDelete(params.id);
					}}
				>
					<Delete /> <p className="m-0">Delete Job</p>
				</MenuItem>
			</BurgerMenu>,
		],
	},
];
