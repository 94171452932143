import * as yup from "yup";
import { phoneRegExp } from "constants/appConstants";
import { statesList } from "core/utils/statesList";
import { getText } from "core/utils/getContentText";
import { InputAdornment } from "@mui/material";

const getTextContent = (key, type) => getText(`profile.${type}.${key}`);

const contractorValidationSchema = yup.object({
	companyName: yup
		.string("Enter Company Name")
		.required("Company Name is required"),
	companyAddress1: yup
		.string("Enter Address1")
		.required("Address1 is required"),
	companyAddress2: yup
		.string("Enter Address2")
		.required("Address2 is required"),
	city: yup.string("Enter City").required("City is required"),
	state: yup.string("Enter State").required("State is required"),
	zipCode: yup.string("Enter Zip Code").required("Zip Code is required"),

	mobileNumber: yup
		.string("Enter your Phone number")
		.required("Phone number is required")
		.matches(phoneRegExp, "Phone number is not valid"),
	marketSectors: yup
		.string("Select Market Sectors")
		.required("Market Sectors is required"),
	companyType: yup
		.string("Select Company Type")
		.required("Company Type is required"),
	suppliersAndServices: yup
		.string("Select Services")
		.required("Services is required"),
	// serviceState: yup
	// 	.string("Select Service State")
	// 	.required("Services are required"),
	serviceAreas: yup
		.array()
		.of(yup.string())
		.min(1, "Select at least one Service Area")
		.required("Areas are required"),
});
export const contractorProfile = {
	steps: Array.from({ length: 3 }, (_, index) =>
		getText(`profile.contractor.step${index + 1}`)
	),
	stepDescription: {
		step1: {
			heading: getText("profile.contractor.stepDescription.step1.heading"),
			description: getText("profile.contractor.stepDescription.step1.text"),
		},
		step2: {
			heading: getText("profile.contractor.stepDescription.step2.heading"),
			description: getText("profile.contractor.stepDescription.step2.text"),
		},
	},
	formFields: {
		step0: [
			{
				key: "companyName",
				placeholder: "Company Name",
				label: getTextContent("companyName", "contractor"),
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "companyAddress1",
				placeholder: "Address Line 1",
				label: getTextContent("companyAddress1", "contractor"),
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "companyAddress2",
				placeholder: "Address Line 2",
				label: getTextContent("companyAddress2", "contractor"),
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "city",
				placeholder: "City",
				label: getTextContent("city", "contractor"),
				type: "text",
				required: true,
				width: "33%",
				// className: "col-4 col-xs-12",
			},
			{
				key: "state",
				placeholder: "State",
				label: getTextContent("state", "contractor"),
				required: true,
				width: "33%",
				// className: "col-4 col-xs-12",
			},
			{
				key: "zipCode",
				label: getTextContent("zipCode", "contractor"),
				placeholder: "Zip Code",
				type: "text",
				required: true,
				width: "33%",
				// className: "col-4 col-xs-12",
			},
			{
				key: "companyWebsite",
				placeholder: "Company Website",
				label: getTextContent("companyWebsite", "contractor"),
				type: "text",
				width: "50%",
			},
			{
				key: "mobileNumber",
				placeholder: "mobileNumber",
				label: getTextContent("mobileNumber", "contractor"),
				type: "text",
				required: true,
				width: "50%",
				extraInputProps: {
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span>
							</InputAdornment>
						),
					},
				},
			},
			{
				key: "landline",
				label: getTextContent("landline", "contractor"),
				type: "text",
				width: "50%",
				extraInputProps: {
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span>
							</InputAdornment>
						),
					},
				},
			},
		],
		step1: [
			{
				key: "marketSectors",
				placeholder: "Select Market Sectors",
				label: getTextContent("marketSectors", "contractor"),
				required: true,
				type: "select",
				select: true,

				options: [
					"Commercial",
					"Healthcare",
					"Industrial and Eneger",
					"Infrastructure",
					"Institutional",
					"Residential",
				],
			},
			{
				key: "companyType",
				placeholder: "Select Company Type",
				label: getTextContent("companyType", "contractor"),
				type: "select",
				select: true,

				options: [
					"Architects",
					"Engineers",
					"General Contractors",
					"Owner / Real Estate Developer",
					"Speciality Contractors",
					"Supplier and Vendors",
					"Consultants",
				],
			},

			{
				key: "suppliersAndServices",
				placeholder: "Select Services",
				label: getTextContent("suppliersAndServices", "contractor"),
				type: "select",
				select: true,
				options: [
					"Communication",
					"Concrete",
					"Demolition",
					"Design & Engineering",
					"Earthwork",
					"Electrical",
					"Electronic Security",
					"Fire Suppression",
					"Heating Ventilation & Air Conditioning (HVAC)",
					"Landscaping",
					"Masonry",
					"Plumbing",
					"Project Management",
					"Roofing",
					"Rough Carpentry",
					"Structural Steel",
				],
			},
		],
		step2: [
			{
				key: "serviceAreas",
				placeholder: "Select your Areas",
				label: getTextContent("serviceAreas", "contractor"),
				type: "select",
				select: true,
				className: "multi-select-option",
				multiple: true,
				options: statesList,
			},
		],
	},
	validationSchema: contractorValidationSchema,
	defaultState: (defaultState) =>
		defaultState ||
		Object.fromEntries(
			Object.keys(contractorValidationSchema.fields).map((key) =>
				key === "serviceAreas" ? [key, []] : [key, ""]
			)
		),
};
