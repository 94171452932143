import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getProjects } from "selectors/projectSelector";

const ProjectContext = React.createContext();

export const ProjectProvider = ({ children }) => {
	const [selectedProjectContext, updateProjectContext] = React.useState({});
	const { projectsList } = useSelector(getProjects);

	const setProjectContext = (project) => {
		console.log("In SetGlobalProject", project);
		updateProjectContext(project);
	};

	useEffect(() => {
		// dispatch(fetchProjects());
		setProjectContext(projectsList[0]);
	}, [projectsList]);

	return (
		<ProjectContext.Provider
			value={{ selectedProjectContext, setProjectContext }}
		>
			{children}
		</ProjectContext.Provider>
	);
};

export default ProjectContext;
