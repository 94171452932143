import { useState } from "react";
import { useDispatch } from "react-redux";
import { contractorProfile } from "./profileUtils";
import { useFormik } from "formik";
import { palette } from "themes";
import { Check, CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { pick, keys, get } from "lodash";
import { saveBusinessProfile } from "actions/profileActions";
import { useNavigate } from "react-router-dom";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";
import { CopilotFooter, CopilotHeader } from "components/Header";
import { getText } from "core/utils/getContentText";
import FormInputField from "components/TextFields/FormInputField";

export const ContractorBusinessProfile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [activeStep, setActiveStep] = useState(0);

	const { steps, formFields, validationSchema, stepDescription } =
		contractorProfile;
	const initialState = contractorProfile.defaultState();

	const [companyDataSaved, setCompanyDataSaved] = useState({});
	const [showAlert, setShowAlert] = useState(false);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			console.log("Check the Onsubmit : ");
		},
	});

	const handleNext = () => {
		const currentFields = formFields[`step${activeStep}`]
			.filter((field) => field.required)
			.map((field) => field.key);
		const currentTouchedFields = pick(formik.touched, currentFields);
		const currentErrorFields = pick(formik.errors, currentFields);
		if (activeStep + 1 < steps.length) {
			if (
				currentFields.length === keys(currentTouchedFields).length &&
				keys(currentErrorFields).length === 0
			) {
				setActiveStep(activeStep + 1);
			}
		}
		if (activeStep + 1 === steps.length) {
			if (keys(formik.errors).length === 0) {
				dispatch(
					saveBusinessProfile(formik.values, (payload) => {
						setCompanyDataSaved(payload);
						if (payload.status === "error") {
							setShowAlert(true);
						}
					})
				);
			}
		}
	};

	function CustomStepIcon(props) {
		const { completed, index } = props;
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: 40,
					height: 40,
					borderRadius: "10%",
					background: completed
						? "#36B37E"
						: index === activeStep
						? palette.bluePrimary
						: "lightgray",
					color: "white",
					border: "none",
					cursor: "pointer",
				}}
			>
				{completed ? <Check /> : index + 1}
			</div>
		);
	}

	return (
		<>
			<CopilotHeader />
			<div className="registration-form pb-5 pt-0">
				<SnackBarWithInfoAlert
					open={showAlert}
					autoHideDuration={2000}
					alertSeverity={companyDataSaved.status}
					alertMessage={companyDataSaved?.message}
					handleClose={() => {
						setShowAlert(false);
						navigate("/");
					}}
				/>
				{companyDataSaved.status === "success" ? (
					<ProfileConfirmationPage navigate={navigate} />
				) : (
					<>
						<Stepper
							alternativeLabel
							activeStep={activeStep}
							className="stepperHeader"
						>
							{steps.length > 1 &&
								steps.map((label, index) => (
									<Step key={label}>
										<StepLabel
											StepIconComponent={(props) => (
												<CustomStepIcon {...props} index={index} />
											)}
											StepIconProps={{ color: green[500] }}
										>
											<span style={{ fontWeight: 700 }}>{label}</span>
										</StepLabel>
									</Step>
								))}
						</Stepper>
						<form onSubmit={formik.handleSubmit}>
							<div className="stepContent container row">
								<div>
									<Typography variant="subtitle1" className="text-left m-1">
										<b>
											{get(stepDescription, `step${activeStep}.heading`, "")}
										</b>
									</Typography>
									<div>
										<p className="m-1" style={{ fontSize: "10px" }}>
											<b>
												{get(
													stepDescription,
													`step${activeStep}.description`,
													""
												)}
											</b>
										</p>
									</div>
								</div>
								{formFields[`step${activeStep}`].map((field, index) => {
									const extraProps = get(field, "extraInputProps", {});
									return (
										<FormInputField
											field={field}
											formik={formik}
											value={formik.values[field.key]}
											key={field.key}
											{...extraProps}
										/>
									);
								})}
								<div style={{ textAlign: "right" }}>
									<div className="d-flex justify-content-between">
										<div>
											{activeStep !== 0 && (
												<button
													type="submit"
													className={`save-details-button m-4 primaryButton`}
													onClick={() => {
														setActiveStep(activeStep - 1);
													}}
												>
													{"< Previous"}
												</button>
											)}
										</div>
										<div className="float-right">
											<button
												type="submit"
												className={`save-details-button m-4 primaryButton`}
												onClick={() => {
													handleNext();
												}}
											>
												{activeStep + 1 === steps.length ? "Save" : "Next >"}
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
			<CopilotFooter />
		</>
	);
};

const ProfileConfirmationPage = ({ navigate }) => {
	return (
		<div>
			<div className="fullHeight">
				<div className="confirmation-container text-center align-center place-center">
					<div className="confirmation-message1">
						<CheckCircleOutlineOutlined className="check-circle" />
						<h3>{getText("profile.profileConfirmationPage.heading")}</h3>
					</div>
					<div className="confirmation-message2 mt-3">
						<p>
							{getText("profile.profileConfirmationPage.visibilityMessage")}
						</p>
					</div>
					<div>
						<button
							onClick={() => {
								navigate(`/`);
							}}
							className="primaryButton mt-3"
							style={{ width: "auto" }}
						>
							Go To Dashboard
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractorBusinessProfile;
