import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import { useLayoutState } from "../../context/LayoutContext";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileDetails } from "selectors/appSelector";
import { decodeToken, isActivateUserSignIn } from "core/utils/appUtils";
import { externalRoutes, routePaths } from "routes";
import { fetchProjects } from "actions/projectActions";
import { loadUserDetails } from "actions/login_registrationActions";
import { get } from "lodash";
import PageNotFound from "components/PageNotFound/PageNotFound";
import { AppRoleProvider } from "context/AppRoleContext";

export const AppWrapper = () => {
	if (isActivateUserSignIn()) {
		return (
			<>
				<Routes>
					{externalRoutes.map((route) => (
						<Route
							key={route.path}
							path={route.path}
							element={route.component}
						/>
					))}
					<Route path="*" element={<AuthorizedRoutes />} />
				</Routes>
			</>
		);
	} else {
		localStorage.removeItem("token");

		return <Navigate to="/login" replace />;
	}
};

export const AuthorizedRoutes = () => {
	const dispatch = useDispatch();
	const layoutState = useLayoutState();
	const userProfile = useSelector(getUserProfileDetails);
	const decodedTokenData = decodeToken();

	const unique_name = get(decodedTokenData, "unique_name", null);

	useEffect(() => {
		dispatch(loadUserDetails(unique_name));
		dispatch(fetchProjects({ page: 0, pageSize: 100 }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const classes = useStyles();

	return (
		<AppRoleProvider>
			<div>
				<div className={classes.root}>
					<Header />
					<Sidebar userProfile={userProfile} />
					<div
						className={classnames(classes.content, {
							[classes.contentShift]: layoutState.isSidebarOpened,
						})}
					>
						<div className={classes.fakeToolbar} />
						<div className="app-container">
							<Routes>
								{routePaths.map((route) => (
									<Route
										key={route.path}
										path={route.path}
										element={
											validateRoute(userProfile, route) ? (
												route.component
											) : (
												<PageNotFound />
											)
										}
									/>
								))}
								<Route path="/404" element={<PageNotFound />} />
								<Route path="*" element={<Navigate to="/404" replace />} />
							</Routes>
						</div>
					</div>
				</div>
			</div>
		</AppRoleProvider>
	);
};

const validateRoute = (userProfile, route) => {
	const roleName = get(userProfile, "role.applRoleName", "");

	if (!route.roles || roleName === "") {
		return true;
	} else if (roleName === "SystemAdmin") {
		return true;
	} else {
		return route.roles.includes(roleName);
	}
};

export default AppWrapper;
