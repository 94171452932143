import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jwtDecode } from "jwt-decode";
import {
	faSpinner,
	faCheck,
	faClock,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

export const isActivateUserSignIn = () => {
	const decodedTokenData = decodeToken();
	const currentTime = Math.floor(Date.now() / 1000);
	return decodedTokenData && decodedTokenData.exp >= currentTime;
};

export const decodeToken = () => {
	const token = localStorage.getItem("token");
	if (token) {
		return jwtDecode(token);
	}
	return null;
};

export function convertCamelToNormal(camelCaseString) {
	// Split the camelCaseString by finding all capital letters preceded by a lowercase letter
	// This regex matches positions where a lowercase letter is followed by an uppercase letter
	let words = camelCaseString.split(/(?=[A-Z])/);

	// Capitalize each word
	for (let i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
	}

	// Join the words into a single string with spaces in between
	let normalCaseString = words.join(" ");

	return normalCaseString;
}
export function calculateProjectStatusCounts(projects, key = "stage") {
	// Initialize an object to store the counts
	const statusCounts = {
		Pending: 0,
		Completed: 0,
		"In Progress": 0,
		Planning: 0,
	};

	// Count the projects for each status
	projects.forEach((project) => {
		if (statusCounts.hasOwnProperty(project[key])) {
			statusCounts[project[key]]++;
		} else {
			// If we encounter a status not in our initial object, we can add it here
			statusCounts[project[key]] = 1;
		}
	});

	return statusCounts;
}

export const getStatus = (status) => {
	if (status === "InProgress") {
		return getStatusIcon(faSpinner, "#FFA500", "1.5rem", status);
	}
	if (status === "Planning" || status === "Pending") {
		return getStatusIcon(faClock, "#FFA500", "1.5rem", status);
	}
	if (status === "Completed") {
		return getStatusIcon(faCheck, "#008000", "1.5rem", status);
	}
	if (status === "Identified") {
		return getStatusIcon(faTriangleExclamation, "#FF0000", "1.5rem", status);
	}
};

export const getStatusIcon = (icon, color, size, matchStatus) => (
	<FontAwesomeIcon
		icon={icon}
		color={color}
		size={size || "1.5rem"}
		data-testid={`${matchStatus}-icon`}
	/>
);

export const projectStatusOptions = [
	"Planning",
	"Pending",
	"In Progress",
	"Completed",
];
